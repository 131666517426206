<template>
  <div id='app_footer'>
    <div class="footer_box">
      <div class="left_list">
        <div class="box">
          <!-- 网址导航 -->
          <ul class="list_ul">
            <p class="list_tit"> {{$t('footers.footerHeader')}}</p>
            <li class="lihover" @click="goRouter('/saas')">{{$t('footers.footerList')}}</li>
            <li class="lihover" @click="goRouter('/product')">{{$t('footers.footerList2')}}</li>
            <li class="lihover" @click="goRouter('/resolvecase')">{{$t('footers.footerList3')}}</li>
            <li class="lihover" @click="goRouter('/about')">{{$t('footers.footerList4')}}</li>
          </ul>
          <!-- 企业荣誉 -->
          <ul class="list_ul">
            <p class="list_tit">{{$t('footers.footerHeader2')}}</p>
            <li>{{$t('footers.footerList5')}}</li>
            <li>{{$t('footers.footerList6')}}</li>
            <li>{{$t('footers.footerList7')}}</li>
            <li>{{$t('footers.footerList8')}}</li>
          </ul>
          <!-- 热门产品 -->
          <ul class="list_ul">
            <p class="list_tit">{{$t('footers.footerHeader3')}}</p>
            <li class="lihover" @click="goUrl(1)">cloudwallet</li>
            <li class="lihover" @click="goUrl(2)">{{$t('footers.footerList9')}}</li>
            <li class="lihover">{{$t('footers.footerList10')}}</li>
            <li class="lihover">{{$t('footers.footerList11')}}</li>
          </ul>
          <!-- <ul class="list_ul">
            <p class="list_tit">合作伙伴</p>
            <li>名称1</li>
            <li>名称1</li>
            <li>名称1</li>
            <li>名称1</li>
          </ul> -->
        </div>

      </div>
      <!-- 联系我们 -->
      <div class="right_tet">
        <ul class="list_ul">
          <p class="list_tit">{{$t('footers.footerHeader4')}}</p>
          <li> <img src="../../assets/footer_ex.png" alt=""> ylzhqkl@163.com</li>
          <li><img src="../../assets/footer_ph.png" alt=""> 13554151304</li>
          <li>{{$t('footers.footerAdress')}}</li>
        </ul>
        <ul class="list_code">
          <li>
            <p>{{$t('footers.footerPic')}}</p>
            <img src="../../assets/home_two.jpg" alt="">
          </li>
          <li>
            <p>{{$t('footers.footerPic2')}}</p>
            <img src="../../assets/home_one.jpg" alt="">
          </li>
        </ul>
      </div>
    </div>
    <div class="copyrights">
      <p class="list_font">Copyright © 2021 武汉市云链智慧区块链科技有限公司</p>
      <div style="min-width: 10px;"></div>
      <a href="http://beian.miit.gov.cn" target="_blank">
        <p class="list_font">鄂ICP备19030536号-1</p>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    // 跳转外部网页
    goUrl(e) {
      if (e == 1) {
        window.open("http://cloudwallet.ylkj.top/#/") 
      } else if (e == 2) {
        window.open("https://jie1.top/#/")
      }
    },
    // 跳转内部页面
    goRouter(url) {
      this.$router.push({
        path: url,
      });
    },
  },
};
</script>
<style lang='scss' scoped>
#app_footer {

  .copyrights {
    display: flex;
    justify-content: center;
    p {
      &:last-child {
        cursor: pointer;
      }

      &:last-child:hover {
        color: rgba(194, 202, 219, 0.38);
      }
    }
  }

  height: 360px;
  background: #3b4456;
  .footer_box {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    padding-top: 56px;
    justify-content: space-between;
    .left_list {
      .box {
        box-sizing: border-box;
        display: flex;
        height: 240px;
        .list_ul {
          width: 160px;
          margin-right: 20px;
          .list_tit {
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 16px;
          }
          li {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 12px;
          }
          .lihover:hover {
            color: #3075FF;
          }
        }
      }
    }
    .right_tet {
      display: flex;
      .list_ul {
        width: 330px;
        margin-right: 20px;
        .list_tit {
          font-size: 18px;
          font-weight: 400;
          color: #ffffff;
          margin-bottom: 16px;
        }
        li {
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          img {
            margin-right: 8px;
            width: 18px;
            height: 18px;
          }
        }
      }
      .list_code {
        display: flex;
        li {
          margin-left: 30px;
          p {
            font-size: 12px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            margin-bottom: 10px;
          }
          img {
            width: 98px;
            height: 98px;
          }
        }
      }
    }
  }
  .list_font {
    text-align: center;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
  }
}
</style>