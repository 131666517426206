import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
const zh = {
  header:{
    home:"首页",
    sass:"SaaS产品",
    product:"产品中心",
    productWallet:"云链钱包",
    productCard:"云链存证",
    producteasy:"云链物易",
    productbrowser:"区块链浏览器",
    case:"解决方案",
    caseHaer:"政务民生解决方案",
    caseHaer2:"放心金融解决方案",
    caseHaer3:"医疗健康解决方案",
    caseHaer4:"综合应用解决方案",
    caseList:"智慧环保",
    caseList2:"智慧农贸",
    caseList3:"农产品溯源",
    caseList4:"电子证照",
    caseList5:"票据流转",
    caseList6:"数字物流",
    caseList7:"职业资格认证",
    caseList8:"权益证明",
    caseList9:"保险管理",
    caseList10:"区块链贷款征信",
    caseList11:"供应链金融",
    caseList12:"支付清算",
    caseList13:"金融审计",
    caseList14:"处方流转",
    caseList15:"食品安全溯源",
    caseList16:"区块链防疫",
    caseList17:"健康服务",
    caseList18:"防伪溯源",
    caseList19:"善款追溯",
    caseList20:"区块链结算系统",
    caseList21:"区块链版权",
    about:"了解云链",
    addwe:"加入云链",
    apply:"生态应用"
  },
  home:{
    bannerTitel:"一个去中心化的基因健康生态链",
    bannerTitelValle:"基于区块链的数据交易和数据服务，为您提供更清晰，更准确的全方位用户画像",
    bannerWe:"关于我们",
    bannerWeValle:"云链科技起源于2016年，是一家以全球区块链技术应用开发作为主攻方向的高科技企业。公司由资深企业家、区块链顶尖IT人士共同组成，主要从事于有关区块链技术的操作系统研发定制，有关区块链场景的落地应⽤的设计与制作。已独⽴完成公有链（cloudchain），私有链，联盟链和数据上链等研发。公司主营区块链技术开发、技术支持、技术服务，致力于将区块链技术应用于各行各业。",
    caseTitle:"全球专业区块链技术解决服务商",
    caseTitleValue:"云链科技致力于区块链底层技术研发、自主知识产业公链、区块链应用前瞻性研究",
    caseList:"集展示中心、数据中心、研判预警中心、运行指挥中心于一体，形成城市环卫管理的“智慧大脑”的云链智慧环卫数据中心平台系统。",
    caseList2:"基于大数据和区块链的农产品质量安全溯源技术体系与标准规范，融合食用农产品生产、加工、储运、检验信息的云链智慧农贸平台。",
    caseList3:"农产品溯源平台，利用区块链和物联网技术，解决了溯源信息的真实性问题。通过营销能力，驱动业务快速增长。",
    caseList4:"基于区块链、身份认证以及可信数据服务平台构建的电子证照平台。实现电子证照“一次生成、多方复用”，为政务异地通办提供多方信任基础设施。",
    caseList5:"区块链票据流转平台结合区块链不可篡改、可追溯的特性，为财政等其他部门提供了票据上链和流转等服务，在保护隐私安全的情况下，为医疗票据等信息在异地报销等多个政务民生场景中的应用提供了技术保障。",
    caseList6:"基于区块链、分布式数字身份、可信计算、隐私保护和金融级风控等技术，解决物流运输及资金真实性证明问题，实现数据交叉核验。适用于公路货运、航运、多式联运等多重物流及供应链服务场景。",
    caseList7:"整合职业资格认证各参与方加入到区块链联盟中，构建教育与职业领域内完善的职业资格认证生态，为职业认证提供完整的合作基础。",
    caseList8:"为作品内容生产机构或内容运营企业提供集权益存证、传播监测、电子取证、司法维权诉讼为一体的一站式线上版权保护解决方案。",
    caseList9:"个人的健康状况、发生事故记录等信息可能会上传至区块链中，使保险公司在客户投保时可以更加及时、准确地获得风险信息，从而降低核保成本、提升效率。",
    caseList10:"获得授权后可通过直接调取区块链的相应信息数据直接完成征信，而不必再申请征信信息查询。",
    caseList11:"区块链技术能有效地解决货物在仓储、物流、监管环节的信息不对称问题，基于区块链数字资产进行融资，企业提供了便捷有效的融资途径。",
    caseList12:"借助区块链技术极大提高支付清算的速度、成本，透明性及安全性。",
    caseList13:"区块链技术能够保证所有数据的完整性、永久性和不可更改性，可有效解决审计行业在交易取证、追踪、关联、回溯等方面的难点和痛点。",
    caseList14:"确保处方安全可信的在医院、药房、患者间流转，确保处方信息不被篡改，确保处方一次性配药效力。",
    caseList15:"从生产、加工、包装到运输、食品流转的全过程相关信息实时上链，保障食品安全。",
    caseList16:"每一个人的行踪都有迹可循，第一时间控制传染源，把疫情伤害降到最低。",
    caseList17:"保证个人健康数据安全、可信、共享及流转，从而实现病人家属远程签字确认，健康数据跨机构流转、追踪和审计。",
    caseList18:"追溯商品详细信息，如原产地、企业/工厂认证信息、检测报告等，保障商家和消费者权益。",
    caseList19:"保障每一笔善款的来源及流通的真实性，让每一笔善款都落到实处，实现最大的价值。",
    caseList20:"区块链结算系统，交易流程公开透明，具有效率高、费用低，可信度高的优点。",
    caseList21:"区块链版权平台，具有全流程降本提效、快速便捷确权登记、可信高效力取证、高精度全网监测、可信高效力取证的优势。",
    advantageTitle:"我们的核心竞争优势",
    advantageIntroduce:"云链科技致力于区块链底层技术研发、自主知识产业公链、区块链应用前瞻性研究",
    advantageListTitle:"支持多链对接",
    advantageListTitle2:"应用场景广泛",
    advantageListTitle3:"自主知识产权",
    advantageListTitle4:"区块链底层技术研究",
    advantageListValue:"支持BTC、ETH、超级账本等多个公链对接应用场景广泛",
    advantageListValue2:"涉及政务、金融、农业等多个领域的场景应用",
    advantageListValue3:"拥有两大发明专利，10大软件著作权",
    advantageListValue4:"高效存储技术、共识机制研究和应用",
    productTitle:"精心打造的明星产品",
    productIntroduce:"针对各行业业务特性，打造个性化解决方案",
    productName:"区块链域名",
    productList:"SaaS平台是标准化区块链产品，可便捷的提供灵活定制、即时部署、快速集成的应用平台",
    productList2:"云链钱包是一款集数字货币管理、存储、转移、增值等为一体的数字资产管理工具",
    productList3:"云链存证是一款集链上存证、数字存证和确权鉴真增信的自研产品",
    productList4:"区块链域名是一款集界一域名、域名浏览器、定制域名和域名商城的自研产品",
    productBtn:"查看详情",
    customizedTitle:"定制化产品服务",
    customizedTitleVallue:"云链科技致力于区块链底层技术研发、自主知识产业公链、区块链应用前瞻性研究",
    customizedHeader:"定制化开发",
    customizedHeader2:"底层开发",
    customizedList:"高效低成本",
    customizedList2:"数据追踪",
    customizedList3:"多节点的系统",
    customizedList4:"智能合约",
    customizedList5:"通用版本",
    customizedList6:"定制版本",
    customizedVallue:"在区块链网络中，在保证信息安全的同时完成价值转移和交换的摩擦成本几乎为零",
    customizedVallue2:"区块链中的数据可追溯本源，信息公开透明便于追踪和验证，利用区块链技术可为物品或数据构造一套不可篡改的记录。",
    customizedVallue3:"区块链中的每个节点都参与数据记录和存储，部分节点损坏时系统仍可正常运转，可持续性和安全性得到保障。",
    customizedVallue4:"区块链中每笔交易内嵌了脚本概念，使得价值交互活动可在特定条件下才执行，可规定具体交易资金的用途和方向。",
    customizedVallue5:"网络部署：一键部署、混合部署、可拔插共识、公信节点背书",
    customizedVallue6:"网络管理：节点控制、节点添加、节点撤销、轻节点授权",
    customizedVallue7:"网络管理：可视化监控、稳定运维、区块链浏览器、开发API、实时监控、可视化部署、多语言架构",
    partnerTitle:"专业可靠的合作伙伴",
    businessTitle:"商务合作请联系我们"
  },
  sass:{
    bannerTitle:"云链SaaS平台",
    bannervallue:"云链SaaS平台是标准化区块链产品，可便捷的提供灵活定制、即时部署、快速集成的应用平台，能够提供基于web的应用定制、开发、部署工具，能够实现无编程的SaaS应用、稳定、部署实现能力。并且能够确保产品迅速地稳定、部署，确保应用能够以较高水平的性能运行。",
    bannerBtn:"立即咨询",
    advantageTitle:"平台优势",
    advantageHeader:"研发成本低",
    advantageHeader2:"减少时间消耗",
    advantageHeader3:"具有灵活性",
    advantageHeader4:"安全性",
    advantageList:"在订阅的基础上提供服务，可根据自身需要扩大和缩减其支出；另外，实现以更低成本获取更多功能和收益",
    advantageList2:"只需进行注册就可以开始使用，可以更好地专注其核心业务",
    advantageList3:"可为客户提供了更大的灵活性。收费基于其使用情况，在从产品中获得额外收益时才会产生更多费用。随着时间的推移逐渐深入使用产品，消除对向一款他们不确定是否能永久满足其需求的软件一次性支付大笔费用的忧虑",
    advantageList4:"使用的信息隔离机制能确保每个客户的信息空间相互独立，用户不能访问非所属企业的任何数据",
    traitTitle:"平台特点",
    traitList:"提供多种版本，不同的价格段不同费用",
    traitList2:"提供免费基础功能，然后增值服务收费，按需定购，选择更加自由",
    traitList3:"灵活启用和暂停，随时随地都可使用",
    traitList4:"准面对面使用指导",
    featuresTitle:"平台功能",
    featuresHeader:"充提币API接口",
    featuresHeader2:"钱包对接",
    featuresHeader3:"支付接口",
    frameworkTitle:"平台架构"
  },
  // 云链钱包
  productWallet:{
    bannerTitle:"云链钱包",
    bannervallue:"Cloud wallet 是云链科技历经2年研发的一款集数字货币管理、存储、转移、增值等为一体的数字资产管理工具，安全便捷、简单易用。除了解决目前数字货币的管理之外，还运用无网技术解决目前数字货币的无网存储，无网转移，冷热钱包一键转换，安全快捷的移动端区块链“新钱包”。",
    featuresTitle:"产品功能",
    featuresHeader:"资产管理",
    featuresHeader2:"无网传输",
    featuresHeader3:"全球化",
    featuresHeader4:"冷热钱包一键转换",
    featuresList:"支持多链存储、多链切换，用户可通过转币收币实现资产流动，并通过全球支付获取自己所需的产品和服务",
    featuresList2:"无网络的情况下也可以转账、收账，随时随地不受网络限制",
    featuresList3:"Cloudwallet 让全球用户都可以方便快捷的使用，开发多语言版本，其中包括：中文、英文、日文、法文、繁体等多语言版本，让全世界用户在使用钱包时，无需担心钱包版本语言问题",
    featuresList4:"实现冷热一键互换功能。CoudWallet将利用技术功能解决当你暂时不需要转移资产的时候，即可切断Cloudwallet网络，即冷钱包；当你需要使用资产的时候，一键链接上网络，即可恢复成热钱包，使资产加一步安全",
    advantageTitle:"产品优势",
    advantageHeader:"非对称加密",
    advantageHeader2:"多场景支付",
    advantageHeader3:"无感交互",
    advantageList:"利用公钥和私钥来加密的方法。数据进行加解密依靠这两个值进行操作，公钥通过私钥而生成，密文通过公钥加密后，只能够用相应的私钥才能解密，所以经过加密后的密文可以在公开的网络环境中传播，即使中途被截获，也会因为没有私钥都不能被解密",
    advantageList2:"随着全新的支付场景出现，Cloudwallet将匹配这个新的支付场景，比如汽车支付，利用Cloudwallet 实现人对随时随地进行交易的需求满足",
    advantageList3:"不再依托具体载体进行交互，而是通过ID-Mapping等弱信用验证技术将用户的强弱ID关联，更高效地完成用户识别和数字资产转移 实现人对随时随地进行交易的需求满足",
  },
  // 云链存证
  product:{
    bannerTitle:"云链存证",
    bannervallue:"云链存证是一款集链上存证、数字存证和确权鉴真增信的自研产品，采用链上、链下协同工作，文件与哈希值分离的方式，链上只保存文件的哈希值，原文件保存在链下，计算出文件的哈希值，与链上的哈希值进行对比，即可查出文件是否被篡改，达到文件防篡改、可追溯、可信任的目的。",
    featuresTitle:"产品功能",
    featuresHeader:"链上存证",
    featuresHeader2:"数字存证",
    featuresHeader3:"确权鉴真增信",
    featuresList:"将文件哈希值上传至NULS公链保存，保证文件防篡改、可追溯、可信任",
    featuresList2:"数字存证证书，链上存证信息一览无余，随时查看你的存证信息",
    featuresList3:"支持存证信息确权、鉴真，可增信",
    advantageTitle:"产品优势",
    advantageHeader:"安全可信",
    advantageHeader2:"数据清晰可查",
    advantageHeader3:"凭证不惧丢失",
    advantageList:"人工审核，脱敏上链，存证数据安全可信任",
    advantageList2:"链上数据信息清晰可查询，随时随地想查就查",
    advantageList3:"数字证书本地保存，不惧信息丢失，一扫皆可知",
  },
  // 云链物易
  producteasy:{
    bannerTitle:"云链物易",
    bannervallue:"云链物易是一款集商城、资产和服务的自研产品，通过区块链随机生成的哈希数值与商品唯一对应，使得企业商品实现数字化，实现唯一：每件商品的商品码唯一、商品码物流路径唯一、商品码与消费者对应关系唯一。可以有效解决商品的防伪溯源、消费者信息安全和隐私泄露的问题。",
    featuresTitle:"产品功能",
    featuresHeader:"商城",
    featuresHeader2:"资产",
    featuresHeader3:"服务",
    featuresList:"区块链商品交易的方式，实现“一物一码”让商品防伪性提升、数据不可篡改也让商品等真实情况",
    featuresList2:"商品接收、商品转赠、商品提取",
    featuresList3:"提升资产交易过程中个人隐私的管理，比如进行匿名交易，减少隐私泄露风险",
    advantageTitle:"产品优势",
    advantageHeader:"物权分离",
    advantageHeader2:"保证产品的真实性",
    advantageHeader3:"减少流通成本",
    advantageList:"商品与消费者对应关系唯一，实现商品数字化",
    advantageList2:"实现“一物一码，可直接了解商品的真实情况",
    advantageList3:"在平台上直接进行商品的交换，减少物流运输成本",
  },
  // 区块链浏览器
  productbrowser:{
    bannerTitle:"区块链浏览器",
    bannervallue:"区块链域名是云链科技独家研发的一款产品，它是未来通往区块链世界的通行证，可以绑定复杂的转账地址、DAPP等一切区块链世界的内容。用户通过区块链浏览器输入相应域名，可查询区块链网上特定内容，就如用户通过浏览器输入网址可查询互联网上制定内容一样。",
    featuresTitle:"产品功能",
    featuresHeader:"界一域名",
    featuresHeader2:"域名浏览器",
    featuresHeader3:"定制域名",
    featuresHeader4:"域名商城",
    featuresList:"界一域名除了具备便捷转转账、快速上网的使用价值，还便于企业、个人IP打造等多方面，域名的唯一性以及稀缺性也将衍生出具有潜力的投资价值",
    featuresList2:"通过解析和处理公链数据，使得单一域名可与全部的公链地址建立映射关系，用户可通过易读取辨识且具备商业价值的域名访问域名浏览器来管理自身的全部区块链资产和信息",
    featuresList3:"支持私人域名定制，无论是公司名、个人姓名、特殊数字，只要你想得到的，具有特殊含义的域名都可定制，特殊域名同时也具备投资价值",
    featuresList4:"个人购买的域名可以在域名商城进行自由交易，域名订单一目了然；域名分类推荐，想买哪类，点哪类；域名加购，购买一键完成",
    advantageTitle:"产品优势",
    advantageHeader:"资源丰富",
    advantageHeader2:"便于管理",
    advantageHeader3:"标识显著",
    advantageList:"区块链域名资源丰富，可开发和利用空间较大，可以获得自己满意的域名",
    advantageList2:"用户可通过易读取辨识且具备商业价值的域名访问域名浏览器来管理自身的全部区块链资产和信息",
    advantageList3:"定制化域名有利于个人和企业IP打造，同时具备投资价值",
  },
  // 解决方案
  case:{
    introduceTitle:"方案介绍",
    introduceBtn:"解决痛点",
    introduceHeader:"借助区块链，环境监测、环保执法、水处理、垃圾处理、废气处理、垃圾分类回收等均可做到公开透明，从而保护城市健康发展。",
    introduceHeader2:"将区块链技术与农贸供销深度融合，通过具有自主知识产权的智慧化与可信化的质量检测与追溯技术助力疫情防控。",
    introduceHeader3:"以农产品追溯码为信息传递载体，实现对各类农产品的种植、加工、流通、仓储及零售等各个环节的全程监控。",
    introduceHeader4:"实现居民身份证等24类常用电子证照上链，在个人隐私得到最大程度保护的基础上，企业市民携带纸质证明办事的不便将大大减少。",
    introduceHeader5:"结合区块链不可篡改、可追溯的特性，可提供票据上链和流转等服务，保护隐私安全的同时，为多个政务民生场景中的应用提供了技术保障。",
    introduceHeader6:"分布式数字身份、业务订单及核验结果区块链存证、可信计算环境中多方数据核验、数据化授信、信用流转、保险联盟等核心。",
    introduceHeader7:"教育职业资格认证服务实现企业的培训行为数据链上存证，将培训课程、培训时长、所获证书等数据上链后进行监管核查，并为联盟成员提供认证数据调用与查询服务，做到信息公开，标准统一。",
    introduceHeader8:"基于区块链特性，集权益存证、传播监测、电子取证、司法维权诉讼为一体的一站式线上保护。",
    introduceHeader9:"个人的健康状况、发生事故记录等信息可能会上传至区块链中，使保险公司在客户投保时可以更加及时、准确地获得风险信息，从而降低核保成本、提升效率。",
    introduceHeader10:"获得授权后可通过直接调取区块链的相应信息数据直接完成征信，而不必再申请征信信息查询。",
    introduceHeader11:"区块链技术能有效地解决货物在仓储、物流、监管环节的信息不对称问题，基于区块链数字资产进行融资，企业提供了便捷有效的融资途径。",
    introduceHeader12:"借助区块链技术极大提高支付清算的速度、成本，透明性及安全性。",
    introduceHeader13:"区块链技术能够保证所有数据的完整性、永久性和不可更改性，可有效解决审计行业在交易取证、追踪、关联、回溯等方面的难点和痛点。",
    introduceHeader14:"确保处方安全可信的在医院、药房、患者间流转，确保处方信息不被篡改，确保处方一次性配药效力。",
    introduceHeader15:"从生产、加工、包装到运输、食品流转的全过程相关信息实时上链，保障食品安全。",
    introduceHeader16:"每一个人的行踪都有迹可循，第一时间控制传染源，把疫情伤害降到最低。",
    introduceHeader17:"保证个人健康数据安全、可信、共享及流转，从而实现病人家属远程签字确认，健康数据跨机构流转、追踪和审计。",
    introduceHeader18:"追溯商品详细信息，如原产地、企业/工厂认证信息、检测报告等，保障商家和消费者权益。",
    introduceHeader19:"保障每一笔善款的来源及流通的真实性，让每一笔善款都落到实处，实现最大的价值。",
    introduceHeader20:"区块链结算系统，交易流程公开透明，具有效率高、费用低，可信度高的优点。",
    introduceHeader21:"为所有原创作品提供作品上链、检测、存证证书等服务，以保户原创作品的版权权益。",
    introduceSolve:"误报、篡改排放数据；各部门数据孤岛，无法互通；民众参与度低",
    introduceSolve2:"食品安全不可追溯；数据信息不明确；交易数据多且杂，无法追溯。",
    introduceSolve3:"安全问题无法及时追溯，种植、加工、流通、仓储、零售过程无法监管。",
    introduceSolve4:"个人信息泄露，数据被篡改；信息难以全面归集；系统不稳定导致整个服务不可用。",
    introduceSolve5:"保密度低，个人信息易泄露；运输、保管、核实成本高；纸质票据难监管，工作量繁重。",
    introduceSolve6:"物流运输及资金真实性；数据交叉核验；物流产业数据协同，改善生产关系；匹配价值服务。",
    introduceSolve7:"资质造假，用户职业培训行为、学习过程和资格证书等权威机构记录",
    introduceSolve8:"缺乏行业规范化服务，交易过程信息不联通，收益难以公平分配，权属纠纷问题；难以掌控传播安全问题。",
    introduceSolve9:"保险行业信息不流通，核保成本大，骗保概率上升；人工操作进程缓慢且冗杂。",
    introduceSolve10:"信息安全问题，征信数据无法市场化，导致征信数据少，质量差，数据和信用相关度较低。",
    introduceSolve11:"供应链上金融系统难以自证清白，对供应链核心企业风控成本大，对供应链上中小企业融资难，成本高，从而进一步影响到整个供应链。",
    introduceSolve12:"时间成本、人工成本高，清算过程中容易出现信息篡改等问题，交易透明度较低。",
    introduceSolve13:"审计体系庞大且复杂对审计人员要求高，导致审计风险、成本的增加，缺乏独立性，容易受到外界干扰，不能完全客观的进行独立审计。",
    introduceSolve14:"患者每次都需重复一系列流程进行复诊，导致复诊麻烦；医院、药店等药品信息匹配困难，处方共享受阻；对于假冒处方、冒用处方、过期处方等监管困难重重。",
    introduceSolve15:"食品加工、流通环节多，存在多处安全隐患；食品流转信息分散，得不到有效监管，导致食品溯源困难。",
    introduceSolve16:"人员大量流动，无法第一时间查询到感染人员的行踪，疫情信息无法共享，容易错失黄金防疫时期，造成不必要的伤害。",
    introduceSolve17:"个人健康数据安全性无法保障，无可信任机构，健康数据无法共享及流转，导致资源浪费。",
    introduceSolve18:"缺乏可信任的防伪溯源体系，市场售假、造假、以次充好现象屡见不鲜，不仅打击消费者的购物体验也损害商家的品牌形象。",
    introduceSolve19:"慈善机构缺少必要监管与审计，信息不公开，运作方式不透明，募捐善款无法追溯，造成慈善机构信用缺失。",
    introduceSolve20:"传统对账不同机构异步处理生成各自业务记录文件逐步对比，时效性查且容易出错；受地域、时间、监管等因素影响，不同机构之间信任体系难以建立。",
    introduceSolve21:"传统的版权认证方式，流程复杂、周期冗长，提高了原创者们版权保护的门槛；数字化作品传播快，且脱离传统版权认证方式，进一步增大创作者的维权难度。",
    advantageList:"跟踪“三废”和碳排放，利于机构监管。",
    advantageList2:"各部门数据互通，高效合作。",
    advantageList3:"普通民众和回收机构接入，日常活动参与减排。",
    advantageList4:"食品到餐桌，全过程追溯。",
    advantageList5:"供应链相关信息全公示。",
    advantageList6:"线上买菜，统一配送，方便快捷。",
    advantageList7:"溯源平台系统，种植信息全管理。",
    advantageList8:"进场、检测、加工，信息采集，全追踪。",
    advantageList9:"查询真伪、追溯来源、透明消费、安全放心。",
    advantageList10:"证照可信，防篡改、可追溯。",
    advantageList11:"打通各部门数据信息，数据共享。",
    advantageList12:"单条信息加密，保障隐私安全。",
    advantageList13:"强化保密，只有授信方，才能查看票据信息，有效防止信息泄露。",
    advantageList14:"各机构电子票据链接，直接审核查验，效率高，易监管。",
    advantageList15:"通过多种方式对上链数据进行交叉比对，在加密环境秒级快速计算，保证数据安全、真实和可信。",
    advantageList16:"经过数据所有者授权，相关利益方可实时获取数据，从而帮助物流产业的上下游企业和个人参与者获取对应服务。",
    advantageList17:"基于区块链共享网络，从物流行业信息不对称、物流企业经营不可信，到数字化业务运营过程，商业合作效率大为改善。",
    advantageList18:"对用户的职业培训进程进行记录，精准匹配课程、班级、学时、成绩、实践等信息，构建真实有效的培训行为数据。",
    advantageList19:"使用区块链技术，记录用户不同学习阶段的职业培训历程以及职业资格证书，全程监管杜绝经历伪造与证书造假。",
    advantageList20:"多维度构建用户技能画像，多领域广泛拓展技能使用场景，包括就业、人社补贴和企业人才筛选与晋升等领域。",
    advantageList21:"将分散的业务环节整合成线上一站式服务，集权益存证、传播监测、电子取证、维权服务为一体。",
    advantageList22:"有效提高因权属问题产生纠纷的解决效率。",
    advantageList23:"打造可信任的权益证明联盟链，关联信息上链后共享，在智能合约与业务应用层内实现整合与数据交换，通过智能合约实现公平实时的收益分配。",
    advantageList24:"解决信息孤岛问题，有效抑制“不良风险”骗保行为的同时也可以帮助简化保险公司核保核赔等日常工作。",
    advantageList25:"保障征信数据安全，从而确立自身数据主权。",
    advantageList26:"取之用户，用于用户，促进数据的开放共享与社会的互联互通。",
    advantageList27:"利用区块链技术运行数字票据，把整个商业中的信用体系变得可传导、可追溯，可有效降低资金风控成本和供应链上中小企业融资难度。",
    advantageList28:"加强对清算过程中的跟踪，极大程度提高资金的安全性，利于部门监管，及时发现清算过程中存在的隐患。",
    advantageList29:"审计手段更加全面，信息准确透彻，方便内外进行查找勘测分析，降低审计分析和审计的成本同时提高审计效率。",
    advantageList30:"利用区块链技术形成医院、药店等多节点联盟网络，电子出发诊疗记录、电子病历多方共享，打破医疗数据孤岛。",
    advantageList31:"开发式节点，每个节点都可以输入数据，节点之间互联关联，每个产品独立溯源码，节点扫码，按时间顺序留下痕迹，确保可塑性。",
    advantageList32:"人员踪迹可追寻，及时查出传染源；保证不同独立机构同时共享疫情相关信息，加快卫生系统在紧急情况下的响应。",
    advantageList33:"利用区块链全程可追溯、防篡改、隐私保护等特性，保障个人健康数据的安全性，同时利于公共卫生事件实时监测、预警。",
    advantageList34:"借助区块链技术，将商品原材料流通过程，生产过程、营销过程的信息进行整合并写入区块链，实现一物一码全流程正品追溯。",
    advantageList35:"利用区块链技术，有效监管善款来源与流向，让善款落到实处。",
    advantageList36:"打破数据信息孤岛，公开捐赠数据及分配数据，做到数据实时可查，建立良好的信任体系。",
    advantageList37:"区块链结算系统通过数字加密，分布式共识建立分布式节点信用关系，从而解决支付节点之间信任建立难，成本高的问题，同时具有效率高、成本低等优点。",
    advantageList38:"传统的版权认证方式，流程复杂、周期冗长，提高了原创者们版权保护的门槛。",
    advantageList39:"数字化作品传播快，且脱离传统版权认证方式，进一步增大创作者的维权难度。",
  },
  // 关于我们
  about:{
    bannerTitel:"关于我们",
    bannerVallue:"全球专业区块链技术解决服务商",
    aboutTitle:"关于云链",
    aboutVallue:"云链科技起源于2016年，是一家以全球区块链技术应用开发作为主攻方向的高科技企业。公司由资深企业家、区块链顶尖IT人士共同组成，主要从事于有关区块链技术的操作系统研发定制，有关区块链场景的落地应⽤的设计与制作。已独⽴完成公有链（cloudchain），私有链，联盟链和数据上链等研发。公司主营区块链技术开发、技术支持、技术服务，致力于将区块链技术应用于各行各业。",
    advantageTitle:"核心优势",
    advantageList:"丰富经验",
    advantageList2:"顶尖团队",
    advantageList3:"行业案例",
    advantageList4:"自主技术",
    advantageCont:"专业的全流程服务，产品经过客户市场验证，稳定、安全，保证项目成功落地；坚持服务实体经济和可持续的商业模式。",
    advantageCont2:"拥有资深企业家、区块链顶尖IT人士，完善的项目实施团队，整个项目环节都有专人负责、提前规划，以及成熟的产品体系。",
    advantageCont3:"在农贸、环保、政务、溯源、数据、资产数字化等众多行业实现应用落地，",
    advantageCont4:"自主研发已独⽴完成公有链（cloudchain），私有链，联盟链和数据上链等研发，拥有多项专利和软件著作权。",
    attestationList:"数字货币交易所撮合实现软件",
    attestationList2:"域名浏览器",
    attestationList3:"以太星球软件",
    attestationList4:"界一域名软件",
    attestationList5:"区块链服务网络合格开发者证书",
    attestationList6:"质量管理体系认证书",
    attestationList7:"武汉房县商会副会长单位",
    attestationList8:"湖北省青年创业促进会<br>副会长单位",
    attestationList9:"湖北省青年创业促进会<br>战略合作伙伴",
    attestationList10:"湖北省青年创业促进会<br>区块链定点培训基地",
    attestationList11:"武汉区块链联盟<br>副理事长单位",
    attestationList12:"全国移动互联网创新大赛<br>区块链应用技术专项赛二等奖",
    attestationList13:"光谷区块链技术应用联盟<br>会长单位",
    attestationList14:"2018年BLOCK HOT<br>全球优秀区块链企业奖",
    attestationList15:"2019年区块链行业<br>最佳技术应用奖",
    attestationList16:"2018年胡润区块链企业排行榜<br>创新企业TOP50",
    attestationList17:"2017年纷智·全球区块链峰会<br>优秀区块链企业",
    attestationList18:"2018云钱包区块链<br>最具投资力项目奖项",
    attestationTitle:"发展路线",
    qualification:"资质认证"
  },
  // 加入云链
  addwe:{
    bannerTitle:"加入我们",
    bannervVallue:"如果你亦环抱着梦想，拥有无限的才华，这里有无限大的舞台任你挥洒",
    welfareTitle:"员工福利",
    welfareList:"充分的基础保障",
    welfareList2:"节日福利",
    welfareList3:"带薪年假",
    welfareList4:"员工关怀",
    welfareList5:"全勤奖励",
    welfareList6:"工龄奖励",
    welfareVallue:"购买养老保险、医疗保险、工伤保险、失业保险和生育保险给予员工基础的社保保障",
    welfareVallue2:"员工福利丰富，包括：交通费补贴、节假日福利、团建活动、绩效奖金等等",
    welfareVallue3:"每年可享受5-15天不等的带薪年假",
    welfareVallue4:"入职后实时跟踪每个员工的工作、生活情况，给予员工力所能及的帮助",
    welfareVallue5:"每月享有额外的200元全勤奖励",
    welfareVallue6:"老员工可享受100-500元不等的工龄奖金",
    jobTitle:"热门岗位",
    jobName:"GO语言开发工程师",
    jobName2:"Java开发工程师",
    jobName3:"Android开发工程师",
    jobName4:"iOS开发工程师",
    jobAdress:"武汉",
    jobYear:"工作经验3-5年",
    jobIntroduce:"从事区块链各大公链的研发工作；",
    jobIntroduce2:"推进区块链技术与公司业务的衔接，需求整理、方案书写；",
    jobIntroduce3:"完成相关开发文档；",
    jobIntroduce4:"负责项目开发、维护、业务需求梳理及对接；",
    jobIntroduce5:"根据需求进行任务拆解与时间评估形成开发计划，确保计划执行，每日更新计划；",
    jobIntroduce6:"注重开发与自测质量，快速定位与解决问题，保障产品稳定运行；",
    jobIntroduce7:"根据产品原型及UI设计图，基于Android平台进行应用程序开发，编写可复用的用户界面组件，实现各模块功能；",
    jobIntroduce8:"持续的优化前端体验和页面响应速度，并保证兼容各类机型和执行效率；",
    jobIntroduce9:"负责公司iOS原生开发；",
    jobIntroduce10:"参与方案讨论和技术调研、负责程序框架设计以及核心功能开发；",
    jobIntroduce11:"独立完成iOS软件开发、测试中发现的问题，确保开发质量；",
    jobBtn:"立即加入",
    deliverTitle:"投递方式",
    deliverName:"联系HR",
    deliverTitle:"添加HR微信，专人服务岗位咨询",
    deliverEamil:"简历发送至招聘邮箱：",
    deliverValue:"HR团队将在收到简历的5个工作日内进行处理。 若简历通过，您将立即收到通知。"
  },
  // 生态
  apply:{
    bannerTitle:"生态应用",
    bannerValue:"在众多合作伙伴与项目的不断努力下，云链生态正在快速发展壮大。",
    applyBtn:"下载",
    applyGoto:"前往",
    applyHeader:"云链钱包",
    applyHeader2:"区块链域名",
    applyHeader3:"云链存证",
    applyHeader4:"云链物易",
    applyHeader5:"农鼎慧云链智慧农贸平台",
    applyHeader6:"云链域名",    
    applyValue:"云链钱包是云链科技历经一年研发的一款集数字货币管理、存储、转移、增值等为一体的数字资产管理工具，安全便捷、简单易用。除了解决目前数字货币的管理之外，将继续迭代丰富其生态应用。",
    applyValue2:"云链科技独家研发的产品，它是未来通往区块链世界的通行证，可以绑定复杂的转账地址、DAPP等一切区块链世界的内容。用户通过区块链浏览器输入相应域名，可查询区块链网上特定内容，就如用户通过浏览器输入网址可查询互联网上制定内容一样。",
    applyValue3:"采用链上、链下协同工作，文件与哈希值分离的方式，链上只保存文件的哈希值，原文件保存在链下，计算出文件的哈希值，与链上的哈希值进行对比，即可查出文件是否被篡改，达到文件防篡改、可追溯、可信任的目的。",
    applyValue4:"通过区块链随机生成的哈希数值与商品唯一对应，使得企业商品实现数字化，实现唯一：每件商品的商品码唯一、商品码物流路径唯一、商品码与消费者对应关系唯一。可以有效解决商品的防伪溯源、消费者信息安全和隐私泄露的问题。",
    applyValue5:"云链区块链农贸示范项目通过面向基于区块链的食用农产品溯源体系，建立批发、配送、零售全溯源链的农产品示范。",
    applyValue6:"云链科技联合BSN-DDC研发发布，可以被适配到所有公有链、联盟链和私有链上，确保域名所有者可以在所有链上控制同一域名指向任何链账户、钱包、合约地址和DID文件。",
    applyTitle:"生态伙伴"
  },
  // 底部
  footers:{
      footerHeader:"网址导航",
      footerHeader2:"企业荣誉",
      footerHeader3:"热门产品",
      footerHeader4:"联系我们",
      footerList:"SaaS产品",
      footerList2:"产品中心",
      footerList3:"解决方案",
      footerList4:"了解云链",
      footerList5:"高新技术企业",
      footerList6:"科技'小巨人'企业",
      footerList7:"质量管理体系认证",
      footerList8:"BSN合格开发者证书",
      footerList9:"区块链域名",
      footerList10:"云链存证",
      footerList11:"云链物易",
      footerAdress:"地址：武汉市高新二路37号鼎泰关南大厦5楼501室",
      footerPic:"云链科技公众号",
      footerPic2:"云链存证小程序"
  },
  ...zhLocale,
}
export default zh
