<template>
  <div class="app_hesder" :class="types == 2 ? 'Rscroll' : ''">
    <div class="center_box">
      <div class="center_box_left"></div>
      <ul class="center_box_right">
        <!-- 首页 -->
        <li :class="paths == '/' ? 'activeTab' : ''" @click="goOther('/')">
          <!-- <router-link to="/">{{$t('header.home')}}</router-link> -->
          {{ $t('header.home') }}
        </li>
        <!-- sass -->
        <li
          :class="paths == '/saas' ? 'activeTab' : ''"
          @click="goOther('/saas')"
        >
          <!-- <router-link to="/saas">{{$t('header.sass')}}</router-link> -->
          {{ $t('header.sass') }}
        </li>
        <!-- 产品中心 -->
        <el-dropdown @command="command">
          <li :class="paths == '/product' ? 'activeTab' : ''">
            {{ $t('header.product') }}
          </li>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="1">{{
              $t('header.productWallet')
            }}</el-dropdown-item>
            <el-dropdown-item command="2">{{
              $t('header.productCard')
            }}</el-dropdown-item>
            <el-dropdown-item command="3">{{
              $t('header.producteasy')
            }}</el-dropdown-item>
            <el-dropdown-item command="4">{{
              $t('header.productbrowser')
            }}</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- 解决方案 -->
        <el-popover
          ref="popverRef"
          placement="bottom"
          trigger="hover"
          popper-class="popverClass"
        >
          <li
            slot="reference"
            :class="paths == '/resolvecase' ? 'activeTab' : ''"
          >
            {{ $t('header.case') }}
          </li>
          <div class="solution">
            <ul class="solution_tit">
              <li v-for="(item, index) in solution" :key="index">
                {{ item.name }}
              </li>
            </ul>
            <div class="solution_list">
              <ul v-for="(item, index) in solution" :key="index">
                <li
                  v-for="(it, i) in item.nav"
                  :key="i"
                  @click="goDeiltes(it.id)"
                >
                  {{ it.tit }}
                </li>
              </ul>
            </div>
          </div>
        </el-popover>
        <!-- <li :class="paths=='/advisory'?'activeTab':''">
          <router-link to="advisory" >动态资讯</router-link>
        </li> -->
        <!-- 了解云链 -->
        <li
          :class="paths == '/about' ? 'activeTab' : ''"
          @click="goOther('/about')"
        >
          <!-- <router-link to="about" >{{$t('header.about')}}</router-link> -->
          {{ $t('header.about') }}
        </li>
        <!-- 加入云链 -->
        <li
          :class="paths == '/addwe' ? 'activeTab' : ''"
          @click="goOther('/addwe')"
        >
          <!-- <router-link to="addwe" >{{$t('header.addwe')}}</router-link> -->
          {{ $t('header.addwe') }}
        </li>
        <!--生态应用  -->
        <li
          v-if="false"
          :class="paths == '/apply' ? 'activeTab' : ''"
          @click="goOther('/apply')"
        >
          <!-- <router-link to="apply" >{{$t('header.apply')}}</router-link> -->
          {{ $t('header.apply') }}
        </li>
      </ul>
      <el-dropdown @command="language">
        <span class="el-dropdown-link">
          {{ languageName }}<i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="中文">中文</el-dropdown-item>
          <el-dropdown-item command="English">English</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>
<script>
import Qs from 'qs';
export default {
  name: 'appHeader',
  data() {
    return {
      paths: '',
      languageName: '中文',
    };
  },
  props: {
    types: {
      type: Number,
      default: 1,
    },
  },
  watch: {
    $route(to, from) {
      if (to.path.indexOf('/product') > -1) {
        this.paths = '/product';
      } else {
        this.paths = to.path;
      }
    },
  },
  computed: {
    solution() {
      return [
        {
          name: this.$t('header.caseHaer'),
          id: '1',
          nav: [
            {
              tit: this.$t('header.caseList'),
              id: 0,
            },
            {
              tit: this.$t('header.caseList2'),
              id: 1,
            },
            {
              tit: this.$t('header.caseList3'),
              id: 2,
            },
            {
              tit: this.$t('header.caseList4'),
              id: 3,
            },
            {
              tit: this.$t('header.caseList5'),
              id: 4,
            },
            {
              tit: this.$t('header.caseList6'),
              id: 5,
            },
            {
              tit: this.$t('header.caseList7'),
              id: 6,
            },
            {
              tit: this.$t('header.caseList8'),
              id: 7,
            },
          ],
        },
        {
          name: this.$t('header.caseHaer2'),
          id: '2',
          nav: [
            {
              tit: this.$t('header.caseList9'),
              id: 8,
            },
            {
              tit: this.$t('header.caseList10'),
              id: 9,
            },
            {
              tit: this.$t('header.caseList11'),
              id: 10,
            },
            {
              tit: this.$t('header.caseList12'),
              id: 11,
            },
            {
              tit: this.$t('header.caseList13'),
              id: 12,
            },
          ],
        },
        {
          name: this.$t('header.caseHaer3'),
          id: '3',
          nav: [
            {
              tit: this.$t('header.caseList14'),
              id: 13,
            },
            {
              tit: this.$t('header.caseList15'),
              id: 14,
            },
            {
              tit: this.$t('header.caseList16'),
              id: 15,
            },
            {
              tit: this.$t('header.caseList17'),
              id: 16,
            },
          ],
        },
        {
          name: this.$t('header.caseHaer4'),
          id: '4',
          nav: [
            {
              tit: this.$t('header.caseList18'),
              id: 17,
            },
            {
              tit: this.$t('header.caseList19'),
              id: 18,
            },
            {
              tit: this.$t('header.caseList20'),
              id: 19,
            },
            {
              tit: this.$t('header.caseList21'),
              id: 20,
            },
          ],
        },
      ];
    },
  },
  methods: {
    // 产品中心
    command(e) {
      let url = '';
      if (e == 2) {
        url = '/product';
      } else if (e == 1) {
        url = '/productWallet';
      } else if (e == 3) {
        url = '/producteasy';
      } else {
        url = '/productbrowser';
      }
      if (this.$route.fullPath == url) {
        return;
      }
      window.open('http://ylzh.pro/#' + url);
      // this.$router.push({
      //     path: url,
      //   });
    },
    // sass
    goSass(e) {
      this.$router.push({
        path: '/saas',
        query: {
          id: e,
        },
      });
    },
    goDeiltes(id) {
      // console.log(this.$store);
      // sessionStorage.setItem("caseid", id);
      // this.$store.commit("setCase", id);
      // if (this.$route.path != "/resolvecase") {
      //   this.$router.push({
      //     path: "/resolvecase",
      //   });
      // }

      window.open('http://ylzh.pro/#/resolvecase?id=' + id);
    },
    // 语言
    language(e) {
      console.log(e);
      this.languageName = e;
      if (e == 'English') {
        localStorage.setItem('lang', 'en');
        this.$i18n.locale = 'en';
      } else {
        localStorage.setItem('lang', 'zh');
        this.$i18n.locale = 'zh';
      }
    },
    // 打开新标签
    goOther(url) {
      console.log(this.$route);
      if (this.$route.fullPath == url) {
        return;
      }
      window.open('http://ylzh.pro/#' + url);
    },
  },
  mounted() {
    this.$refs.popverRef.doShow(); // 显示弹框
    this.$refs.popverRef.doClose(); // 隐藏弹框
  },
  created() {
    this.languageName =
      localStorage.getItem('lang') == 'en' ? 'English' : '中文';
  },
};
</script>
<style lang="scss" scoped="scoped">
/deep/ .el-popover[x-placement^='bottom'] .popper__arrow {
  /*el-popover上边三角下层的颜色---也就是视觉上的边框*/
  border-bottom-color: rgba(54, 229, 255, 1) !important;
}
/deep/ .el-popover[x-placement^='bottom'] .popper__arrow::after {
  /*el-popover上边三角表层的颜色---也就是视觉上的角*/
  border-bottom-color: rgba(8, 40, 100, 1) !important;
}

/deep/ .popper__arrow {
  // border-bottom-color: #1ebef4 !important;
  left: 65% !important;
  // visibility: hidden;
}
/deep/ .el-drawer:focus {
  outline: none;
}

.solution {
  .solution_tit {
    display: flex;
    border-bottom: 1px solid #e5e5e5;
    padding: 16px 20px;
    li {
      width: 208px;
      font-size: 16px;
      font-weight: 600;
      color: #1d2023;
      cursor: pointer;
      text-align: center;
    }
  }
  .solution_list {
    padding: 0px 20px 20px;
    display: flex;
    ul {
      li {
        width: 208px;
        // margin-top: 20px;
        font-size: 14px;
        font-weight: 400;
        color: #3f454b;
        cursor: pointer;
        padding: 10px 0;
        text-align: center;
      }
      li:hover {
        color: rgb(102, 177, 255);
        background-color: rgb(236, 245, 255);
      }
    }
  }
}
.app_hesder {
  position: fixed;
  top: 0;
  z-index: 2000;
  width: 100%;
  height: 80px;
  .center_box {
    margin: 0 auto;
    width: 1200px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .center_box_left {
      height: 39px;
      width: 140px;
      background-image: url('../../assets/header_logo_f.png');
      background-size: 100% 100%;
    }
    .center_box_right {
      display: flex;
      li {
        width: 120px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        opacity: 0.7;
        outline: none;
        cursor: pointer;
        a {
          font-size: 16px;
          color: #fff;
        }
      }
      li:hover {
        color: #fff;
        opacity: 1;
      }
      .activeTab {
        color: #fff;
        opacity: 1;
      }
    }

    .el-dropdown {
      text-align: right;
      width: 120px;
      .el-dropdown-link {
        cursor: pointer;
        font-size: 16px;
        color: #fff;
        opacity: 0.8;
      }
      .el-icon-arrow-down {
        font-size: 12px;
      }
    }
  }
}
// .center_box_actibe {
//   background-color: #fff;
//   .center_box {
//     .center_box_left {
//       background-image: url("../../assets/hesder_logo_b.png");
//     }
//     .center_box_right {
//       li {
//         color: #1d2023;
//         a {
//           color: #1d2023;
//         }
//       }
//     }
//     .el-dropdown-link {
//       color: #1d2023 !important;
//     }
//   }
// }
</style>
