const ko = {
  message: {
    alertWrong: '네트워크 오류입니다. 나중에 다시 시도하십시오.',
    codeFail:'인증 코드를받지 못했습니다.',
    placeholder: {
      phone:'휴대 전화 번호를 입력하십시오.',
      password:'새 암호를 입력하십시오.',
      code: '인증 코드를 입력하십시오.',
      tgm: '프로모션 코드를 입력하십시오.',
      pasText:'비밀번호 8-20 자와 숫자로 구성된 비밀번호를 입력하십시오.'
    },
    saveCode: '인증 코드 받기',
    registered: '등록',
    down: '앱 다운로드' ,
    alert:{
      title:'등록이 성공적이며 앱을 다운로드하십시오.',
      leftBtn:'취소',
      rightBtn:'결정'
    },
    webregistersendcode:{
      code:'성공적으로 보냈습니다.',
      registered:'이 계정은 이미 등록되었습니다.'
    },
    webRegisterComplete:{
      codeFail1:'인증 코드가 만료되었습니다.',
      codeFail2:'인증 코드가 잘못되었습니다.',
      registered:'성공적인 등록',
      tgm1:'발기인을 기입하십시오',
      tgm2:'발기인은 존재하지 않습니다.'
    }
  }
}
export default ko
