import jaLocale from 'element-ui/lib/locale/lang/ja'
const ja = {
    Tips: {
        phonereg: "電話番号のフォーマットが間違っています",
        phone: '電話番号は空欄にしてはいけない',
        code: 'captchaは空ではいけない',
        loginpass: 'パスワードは空ではいけません',
        playpassS: '2度パスワードが一致しません',
        tgm: "招待コードは空ではいけません",
        register: "登録が成功する",
        copy: '複製に成功する',
        modify: "正常に変更されました",
        messagetitle: 'メッセージタイトルを入力してください',
        messagesub: '問題の説明を入力してください',
        messageimg: "写真をアップロードしてください",
        card: "正常に送信されました",
        adress: "引き出し先住所を入力してください",
        mentionnum: "引き出し金額を入力してください",
        toid: "パーティーIDを入力してください",
        outnum: "振込金額を入力してください",
        carrynumber: "数量は手数料より小さくてはいけません",
        notYet: "まだ開いていません",
        nike: 'ニックネームを入力する必要があります',
        nothing: "仮数無数の根拠"
    },
    // 底部导航
    bomtab: {
        home: 'ホーム',
        many: '引用',
        financial: '両替',
        find: '資産',
        my: '個人',
    },
    // 标题栏
    Header: {
        notice: "こうこく",
        noticeDelit: "公告の詳細",
        market: "けはい",
        lang: "言語を切り替える",
        transaction: "取引の詳細"
    },
    login: {
        login: 'ログオンする',
        login2: 'きにゅう',
        phoneregister: '携帯電話の登録',
        phoneregister2: '携帯番号登録',
        phone: '携帯電話の番号',
        pleasephone: '携帯番号を入力してください',
        codetitle: "コード検証",
        pleasecode: '認証コードを入力してください',
        send: 'を送信する',
        newpassword: '新しい暗号',
        pleasenew: 'パスワードを入力してください',
        code: 'captchaログイン',
        password: 'パスワードでログインする',
        complete: '即時登録',
        forget: 'パスワードを忘れた',
        pleaseok: '暗証番号を確認する',
        pleasenewok: "パスワードを再入力してください",
        pleaseinvitation: '招待コード',
        pleasetation: '招待コードを入力してください',
        ok: '即時登録する',
        next: "次のステップ",
        confrimsreset: "リセットを確定する"
    },
    // home 首页
    home: {
        Friend: "親友を誘う",
        Charge: "充造幣",
        Mention: "提造幣",
        Service: "連絡先顧客サービス",
        rise: "上げ幅ランキング",
        bargain: "取引ランキング",
        name: "めいしょう",
        newprice: "最新価格",
        risetitle: "騰落幅",
        weixin: "公式WeChat",
        emial: "公式メールボックス"
    },
    // my 我的
    my: {
        Invitation: "招待コード",
        lang: "言語を切り替える",
        safety: "口座とセキュリティ",
        feedback: "コメントフィードバック",
        about: "永遠交換について",
        portrait: "の階級を下げる",
        name: "と愛称で呼ぶ",
        phone: "携帯電話の番号を結びつける",
        logpassword: "ログインパスワード",
        paypassword: "取引暗号",
        out: "たいしゅつ",
        amend: "改める",
        set: "未設置",
        nameplease: "ニックネームは10文字以内で入力してください",
        placeholderpay: "トランザクションパスワードを入力してください",
        ok: "の先を打ち曲げる",
    },
    // 留言反馈
    feedback: {
        header: "フィードバック",
        title: "題名",
        placeholdertitle: "20文字以下",
        cont: "コンテンツ",
        imgtitle: "スクリーンショット画像のアップロード（オプション）",
        submit: "フィードバックを送信"
    },
    deal: {
        title: "貨幣取引",
        purchase: "買い入れる",
        sell: "売りに出る",
        sorder: "相場の委託",
        xorder: "指し値の委託",
        price: "価格",
        number: "数",
        available: "利用可能",
        volume: "取引高",
        commission: "現在の依頼",
        historycommission: "履歴依頼",
        whole: "すべて",
        amount: "委託総量",
        commissionpci: "委託価格",
        entrusted: "委託総額",
        cancel: "帳消しにする",
        Turnover: "出来高",
        average: "成約価格",
        charge: "手数料",
        Completed: "取引済み",
        Cancelled: "帳消しにする",
        current: "現在の最適価格で取引する",
        Ppassword: "パスワードを入力してください",
        Pamount: "金額を入力してください",
        nopassword: "現在は取引パスワードが設定されていません。設定に行きますか？",
        Pquantity: "数量を入力してください",
        low: "残高が足りない",
        Optional: "自選する",
        confirm: "確認",
        cancel: "キャンセル",
        coin: "貨幣対"
    },
    kline: {
        range: "上昇幅",
        high: "高さ",
        low: "低い",
        minute: "分間",
        hours: "時間",
        day: "日間",
        time: "時間",
        open: "開く",
        collect: "おさめる",
        number: "数",
        List: "注文書を出す",
        deal: "取引が成立する",
        brief: "概要",
        Price: "価格",
        direction: "方向",
        Issuedate: "リリース時間",
        issue: "発行総量",
        circulation: "流通総量",
        Crowdfunding: "衆議価格",
        paper: "白書",
        website: "公式サイト",
        query: "ブロッククエリ",
        introduction: "概要",
        Bid: "購入",
        Offer: "注文を売る",
    },
    // 邀请好友
    friend: {
        teamnum: "チームの総数",
        straightnum: "ダイレクトプッシュ",
        invitelink: "招待リンク",
        myinvite: "私の招待コード",
        createinvite: "招待ポスターを生成する",
        saveimg: "画像を保存する"
    },
    // 资产 Property
    property: {
        header: "通貨口座",
        headerdeilt: "取引記録",
        assets: "資産転換",
        usable: '利用可能',
        freeze: "氷結",
        price: "値",
        convert: "変換",
        record: "基金の記録",
        state: "完了",
        select: "通貨を選んでくだ",
        chainname: "チェーン名",
        qr: "QRコードを長押しして画像を保存します",
        adress: "デポジットアドレス",
        copy: "コピーアドレス",
        hint: "チップ",
        hintcont: "非充電しないでください",
        hintcont2: "資産、そうでない場合、資産は回収されません",
        hintcont3: "最小充電量",
        hintcont4: "最低額未満の預金は入金されず、返品できません",
        hintcont5: "再充電アドレスは頻繁に変更されることはなく、繰り返し再充電できます",
        hintcont6: "情報の改ざんや漏洩を防ぐために、コンピュータとブラウザが安全であることを確認してください。",
        serach: "クイック検索通貨",
        operation: "操作タイプ",
        mentionadress: "提币地址",
        toid: "パーティーID",
        placeholdermen: "引き出し先住所を入力または貼り付けてください",
        placeholdertoid: "IDを入力または貼り付けてください",
        mentionnum: "引き出しの数",
        intercarrier: "交換の数",
        minmention: "最小引き出し額",
        minintercarrier: "インターチェンジの最小数",
        allwithdraw: "すべて",
        allintercarrier: "すべて",
        service: "手数料",
        hintcont7: "最小引き出し額は",
        hintcont8: "資金の安全を確保するため、アカウントのセキュリティポリシーが変更されたり、パスワードが変更されたりした場合は、手動で引き出しを確認します。スタッフが確認するまでしばらくお待ちください。",
        accountnum: "到着量",
        affirmmention: "撤退を確認する",
        affirmout: "転送を確認する",
        mentionaffirm: "撤退確認",
        transfers: "駅構内転送確認",
        outnum: "転送回数",
        general: "通常の撤退",
        stand: "駅構内交流",
        nopassword: "現在、トランザクションパスワードは設定されていません。設定に移動してください",
        success: "成功したトランザクション",
        err: "処理に失敗しました",
        adoptadress: "接收地址",
        sendadress: "受信アドレス",
        practicalnum: "実際に受け取った金額",
        recharges: "預金記録",
        mentionrecharges: "撤退記録",
        transfersrecharges: "ステーション内でレコードを交換する",
        transfer: "転送",
        flash: "フラッシュ償還",
        standtransfer: "内部転送",
        cencel: "キャンセル",
        Currency: "未払い貨幣",
        Approved: "審査が通る",
        Audit: "審査に失敗",
        Withdraw: "お札の成功",
        withdrawal: "お札の中",
        failed: "お札の失敗",
        accomplish:"完成した"
    },
    ...jaLocale,
}
export default ja