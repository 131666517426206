<template>
  <div id="home">
    <!-- 头部banner -->
    <div class="main_banner">
      <el-carousel trigger="click" indicator-position="none" arrow="never" height="660px">
        <el-carousel-item>
          <div class="banner_box">
            <!-- <img src="../assets/banner1.png" alt="" class="banner_img"> -->
            <div class="banner_box_mid">
              <img src="../assets/home_banner.png" alt="" class="banner_mid_img">
              <div class="banner_box_cont">
                  <p class="banner_cont_title">{{$t('home.bannerTitel')}}</p>
                  <p class="banner_cont_english">join us,Make credit more valuable</p>
                  <p class="banner_cont_deilt">{{$t('home.bannerTitelValle')}}</p>
              </div>
              <div class="banner_box_cont2">
                  <p class="banner_box2_title">{{$t('home.bannerWe')}}</p>
                  <p class="banner_box2_value">{{$t('home.bannerWeValle')}}</p>
              </div>
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 解决方案 -->
    <div class="main_solution">
      <p class="main_solution_title1">{{$t('home.caseTitle')}}</p>
      <p class="main_solution_title2">{{$t('home.caseTitleValue')}}</p>
      <el-tabs :stretch="true" v-model="activeName" @tab-click="handleClick">
        <el-tab-pane v-for="(item,index) in solution" :key="index" :label="item.name" :name="item.id">
          <div class="navlist" v-for="(it,i) in item.nav" :key="i">
            <img :src="it.img" alt="">
            <p class="navlist_tit">{{it.tit}}</p>
            <p class="navlist_tet vertical6">{{it.tet}}</p>
          </div>
        </el-tab-pane>

      </el-tabs>
    </div>
    <!-- 竞争优势 -->
    <div class="main_advantage">
      <p class="advantage_left_tit1">{{$t('home.advantageTitle')}}</p>
      <p class="advantage_left_tit2">{{$t('home.advantageIntroduce')}}</p>
      <div class="main_advantage_box">
        <div class="main_advantage_left">
          <ul class="advantage_left_ul">
            <div class="advantage_left_box">
              <li class="advantage_left_li" v-for="(item,index) in advantage" :key="index">
                <img class="advantage_left_img" :src="item.img" alt="">
                <div class="advantage_li_right">
                  <p class="tit1 vertical">{{item.tit1}}</p>
                  <p class="tit2 vertical2">{{item.tit2}}</p>
                </div>
              </li>
            </div>
          </ul>
        </div>
        <div class="main_advantage_right">
          <img src="../assets/home_advantage_img.png" alt="">
        </div>
      </div>

    </div>
    <!-- 精心打造的明星产品 -->
    <div class="main_product">
      <div class="main_product_box">
        <p class="main_product_title1">{{$t('home.productTitle')}}</p>
        <p class="main_product_title2">{{$t('home.productIntroduce')}}</p>
        <ul class="main_product_ul">
          <li class="main_product_li" v-for="(item,index) in product" :key="index">
            <img :src="item.img" alt="" class="li_item_img">
            <p class="li_item_tit">{{item.tit}}</p>
            <p class="li_item_tet vertical3">{{item.tet}}</p>
            <button  @click="goDetails(item.url)">{{$t('home.productBtn')}}</button>
          </li>
        </ul>
      </div>

    </div>
    <!-- 定制化产品服务 -->
    <div class="main_service">
      <div class="service_left">
        <div class="service_left_box">
          <img src="../assets/home_service_log.png" alt="">
          <p class="main_service_title1">{{$t('home.customizedTitle')}}</p>
          <p class="main_service_title2">{{$t('home.customizedTitleVallue')}}</p>
        </div>
      </div>
      <div class="service_right">
        <ul class="service_right_onenav">
          <li @click="serviceOneClick(index)" :class="{oneActive:index == oneCutton}" v-for="(item,index) in service" :key="index">
            <p class="number">{{item.id}}</p>
            <p class="tit">{{item.onetitle}}</p>
          </li>
        </ul>
        <ul class="service_right_twonav" v-if="service[0]">
          <li @click="serviceTwoClick(index)" :class="{twoActive:index == twoCutton}" v-for="(item,index) in service[oneCutton].tewnav" :key="index">
            {{item.twotitle}}
          </li>
        </ul>
        <ul class="service_right_item">
          <li v-for="(item,index) in service[oneCutton].tewnav[twoCutton].list" :key="index">
            <p class="item_tit">{{item.tit}}</p>
            <p class="item_tet vertical3">{{item.tet}}</p>
          </li>
        </ul>
      </div>
    </div>
    <!-- 新闻动态 -->
    <!-- <div class="main_news">
      <div class="main_news_contnent">
        <div class="news_contnent">
          <p class="news_title1">新闻动态</p>
          <p class="news_title2">用几句话概括用几句话概括用几句话概括用几句话概括用几句话概括</p>
          大新闻
          <div class="news_top">
            <img src="../assets/home_new.png" alt="" class="left_img">
            <div class="right_txt">
              <div class="txt">
                <p class="txt_tit vertical">中国通信工业协会区块链专家委员会莅临云</p>
                <p class="txt_tet vertical3">中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临家委员会莅临家委员会莅临家委员会莅临家委员会莅临</p>
              </div>
              <div class="time">
                <p class="time_day">10-19</p>
                <p class="time_year">2021</p>
              </div>
            </div>
          </div>
          4个小新闻
          <ul class="news_bom">
            <li v-for="(item,index) in 4" :key="index">
              <div class="left_time">
                <p class="t_day">10-19</p>
                <p class="t_year">2021</p>
              </div>
              <div class="right_newtxt">
                <p class="n_tit vertical">中国通信工业协会区块链专家委员会莅临云</p>
                <p class="n_tet vertical3">中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临云中国通信工业协会区块链专家委员会莅临家委员会莅临家委员会莅临家委员会莅临家委员会莅临</p>
              </div>
            </li>
          </ul>
        </div>

      </div>
    </div> -->
    <!-- 合作伙伴 -->
    <div class="main_friend">
      <div class="friend_content">
        <div class="friend_top">
          <p class="friend_title">{{$t('home.partnerTitle')}}</p>
          <ul class="friend_logo">
            <img src="../assets/home_friend.png" alt="">
            <!-- <li v-for="(item,index) in 5" :key="index"><img src="" alt=""></li> -->
          </ul>
        </div>
        <div class="friend_center">
          <p class="friend_title">{{$t('home.businessTitle')}}</p>
          <ul class="friend_cardul">
            <li class="friend_cardli">
              <img src="../assets/home_business1.png" class="cardli_left" alt="">
            </li>
          </ul>
        </div>
      </div>
      <div class="friend_bom">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      // 解决方案
      activeName: "1",
      oneCutton: 0,
      twoCutton: 0,
    };
  },
  computed:{
    // advantage 竞争优势
    advantage(){
      return [
        {
          img: require("../assets/home_advantage_icon1.png"),
          tit1: this.$t('home.advantageListTitle'),
          tit2: this.$t('home.advantageListValue'),
        },
        {
          img: require("../assets/home_advantage_icon2.png"),
          tit1: this.$t('home.advantageListTitle2'),
          tit2: this.$t('home.advantageListValue2'),
        },
        {
          img: require("../assets/home_advantage_icon3.png"),
          tit1: this.$t('home.advantageListTitle3'),
          tit2: this.$t('home.advantageListValue3'),
        },
        {
          img: require("../assets/home_advantage_icon4.png"),
          tit1: this.$t('home.advantageListTitle4'),
          tit2: this.$t('home.advantageListValue4'),
        },
      ]
    },
    solution(){
      return  [
        {
          name: this.$t('header.caseHaer'),
          id: "1",
          nav: [
            {
              img: require("../assets/home_solution1_1.png"),
              tit: this.$t('header.caseList'),
              tet:this.$t('home.caseList'),
            },
            {
              img: require("../assets/home_solution1_2.png"),
              tit: this.$t('header.caseList2'),
              tet:this.$t('home.caseList2')
            },
            {
              img: require("../assets/home_solution1_3.png"),
              tit: this.$t('header.caseList4'),
              tet:this.$t('home.caseList4')
            },
            {
              img: require("../assets/home_solution1_4.png"),
              tit: this.$t('header.caseList6'),
              tet:this.$t('home.caseList6')
            },
            {
              img: require("../assets/home_solution1_5.png"),
              tit: this.$t('header.caseList5'),
              tet:this.$t('home.caseList5')
            },
            {
              img: require("../assets/home_solution1_6.png"),
              tit: this.$t('header.caseList8'),
              tet:this.$t('home.caseList8')
            },
            {
              img: require("../assets/home_solution1_7.png"),
              tit: this.$t('header.caseList3'),
              tet:this.$t('home.caseList3')
            },
            {
              img: require("../assets/home_solution1_8.png"),
              tit: this.$t('header.caseList7'),
              tet:this.$t('home.caseList')
            },
          ],
        },
        {
          name: this.$t('header.caseHaer2'),
          id: "2",
          nav: [
            {
              img: require("../assets/home_solution2_1.png"),
              tit: this.$t('header.caseList12'),
              tet:this.$t('home.caseList12')
            },
            {
              img: require("../assets/home_solution2_2.png"),
              tit: this.$t('header.caseList13'),
              tet:this.$t('home.caseList13')
            },
            {
              img: require("../assets/home_solution2_3.png"),
              tit: this.$t('header.caseList9'),
              tet:this.$t('home.caseList9')
            },
            {
              img: require("../assets/home_solution2_4.png"),
              tit: this.$t('header.caseList11'),
              tet:this.$t('home.caseList11')
            },
            {
              img: require("../assets/home_solution2_5.png"),
              tit: this.$t('header.caseList10'),
              tet:this.$t('home.caseList10')
            },
          ],
        },
        {
          name: this.$t('header.caseHaer3'),
          id: "3",
          nav: [
            {
              img: require("../assets/home_solution3_1.png"),
              tit: this.$t('header.caseList14'),
              tet:this.$t('home.caseList14')
            },
            {
              img: require("../assets/home_solution3_2.png"),
              tit: this.$t('header.caseList17'),
              tet:this.$t('home.caseList17')
            },
            {
              img: require("../assets/home_solution3_3.png"),
              tit: this.$t('header.caseList16'),
              tet:this.$t('home.caseList16')
            },
            {
              img: require("../assets/home_solution3_4.png"),
              tit: this.$t('header.caseList15'),
              tet:this.$t('home.caseList15')
            },
          ],
        },
        {
          name: this.$t('header.caseHaer4'),
          id: "4",
          nav: [
            {
              img: require("../assets/home_solution4_1.png"),
              tit: this.$t('header.caseList18'),
              tet:this.$t('home.caseList18')
            },
            {
              img: require("../assets/home_solution4_2.png"),
              tit: this.$t('header.caseList19'),
              tet:this.$t('home.caseList19')
            },
            {
              img: require("../assets/home_solution4_3.png"),
              tit: this.$t('header.caseList21'),
              tet:this.$t('home.caseList21')
            },
            {
              img: require("../assets/home_solution4_4.png"),
              tit: this.$t('header.caseList20'),
              tet:this.$t('home.caseList20')
            },
          ],
        },
      ]
    },
    // 精心打造的明星产品
    product(){
      return [
        {
          img: require("../assets/main_product_icon1.png"),
          tit: this.$t('header.sass'),
          url:"/saas",
          tet: this.$t('home.productList'),
        },
        {
          img: require("../assets/main_product_icon2.png"),
          tit: this.$t('header.productWallet'),
          url:"/productWallet",
          tet: this.$t('home.productList2'),
        },
        {
          img: require("../assets/main_product_icon3.png"),
          tit: this.$t('header.productCard'),
          url:"/product",
          tet: this.$t('home.productList3'),
        },
        {
          img: require("../assets/main_product_icon4.png"),
          tit: this.$t('home.productName'),
          url:"/productbrowser",
          tet: this.$t('home.productList4'),
        },
      ]
    },
    // 定制化产品服务
    service(){
      return [
        {
          onetitle: this.$t('home.customizedHeader'),
          id: "01",
          tewnav: [
            {
              twotitle: this.$t('home.customizedList'),
              list: [
                {
                  tet:this.$t('home.customizedVallue')
                },
              ],
            },
            {
              twotitle: this.$t('home.customizedList2'),
              list: [
                {
                  tet:this.$t('home.customizedVallue2')
                },
              ],
            },
            {
              twotitle: this.$t('home.customizedList3'),
              list: [
                {
                  tet:this.$t('home.customizedVallue3')
                },
              ],
            },
            {
              twotitle: this.$t('home.customizedList4'),
              list: [
                {
                  tet:this.$t('home.customizedVallue4')
                },
              ],
            },
          ],
        },
        {
          onetitle: this.$t('home.customizedHeader2'),
          id: "02",
          tewnav: [
            {
              twotitle: this.$t('home.customizedList5'),
              list: [
                {
                  tet: this.$t('home.customizedVallue5'),
                },
                {
                  tet: this.$t('home.customizedVallue6'),
                },
              ],
            },
            {
              twotitle: this.$t('home.customizedList6'),
              list: [
                {
                  tet:this.$t('home.customizedVallue7')
                },
              ],
            },
          ],
        },
      ]
    },
  },
  created() {},
  methods: {
    // 解决方案
    handleClick(tab, event) {
      console.log(tab, event);
    },
    // 定制化产品服务
    serviceOneClick(index) {
      this.oneCutton = index;
      this.twoCutton=0
    },
    serviceTwoClick(index) {
      this.twoCutton = index;
    },
    // 产品详情
    goDetails(url){
      this.$router.push(url)
    }
  },
};
</script>
<style lang="scss" scoped>
#home {
  .main_banner {
    height: 660px;
    .el-carousel__item:nth-child(2n) {
      background-color: #99a9bf;
    }
    .el-carousel__item:nth-child(2n + 1) {
      background-color: #d3dce6;
    }
  }
  .main_solution {
    width: 1200px;
    margin: 73px auto;
    // min-height: 752px;
    .main_solution_title1 {
      font-size: 36px;
      font-weight: 600;
      color: #1d2023;
      margin: 0 0 12px;
    }
    .main_solution_title2 {
      font-size: 14px;
      font-weight: 400;
      color: #3f454b;
    }
    /deep/ .el-tabs {
      margin-top: 41px;
      .el-tabs__active-bar {
        background: #2f74ff;
      }
      .el-tabs__item {
        font-size: 16px;
        color: #3f454b;
        margin-bottom: 26px;
      }
      .el-tabs__item.is-active {
        font-size: 22px;
        color: #3075ff;
      }
      .el-tab-pane {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        min-height: 254px;
        .navlist {
          width: 280px;
          height: 254px;
          padding: 22px;
          box-sizing: border-box;
          border: 1px solid #fff;
          img {
            width: 40px;
            height: 40px;
          }
          .navlist_tit {
            font-size: 18px;
            font-weight: 600;
            color: #3f454b;
            margin: 10px 0;
          }
          .navlist_tet {
            width: 100%;
            font-size: 14px;
            font-weight: 400;
            color: #3f454b;
            line-height: 22px;
          }
        }
      }
      .navlist:hover {
        box-shadow: 0px 9px 16px 0px rgba(213, 218, 227, 0.76);
        border: 1px solid #3075ff;
      }
    }
  }
  .main_advantage {
    min-height: 660px;
    // min-width: 1920px;
    background: linear-gradient(214deg, #282fb3 0%, #383da0 100%);
    padding-top: 68px;
    box-sizing: border-box;
    .advantage_left_tit1 {
      width: 1200px;
      margin: 0 auto 12px;
      font-size: 36px;
      font-weight: 600;
      color: #ffffff;
    }
    .advantage_left_tit2 {
      width: 1200px;
      margin: 0 auto;
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
    }
    .main_advantage_box {
      display: flex;
      justify-content: flex-start;
    }
    .main_advantage_left {
      width: 55%;
      .advantage_left_ul {
        padding-top: 60px;
        box-sizing: border-box;
        margin-top: 48px;
        background: #fff;
        border-radius: 0px 2px 2px 0px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .advantage_left_box {
          margin-right: 10%;
          min-height: 290px;
          display: flex;
          flex-wrap: wrap;
          align-items: space-between;
          justify-content: space-between;
          width: 590px;
          .advantage_left_li {
            margin-bottom: 50px;
            width: 270px;
            height: 75px;
            display: flex;
            justify-content: space-between;
            .advantage_left_img {
              width: 48px;
              height: 48px;
            }
            .advantage_li_right {
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              margin-left: 16px;
              width: 210px;
              .tit1 {
                font-size: 18px;
                font-weight: 600;
                color: #1d2023;
                overflow: hidden;
                text-overflow: ellipsis; //超出显示三个点
                white-space: nowrap; //不换行
              }
              .tit2 {
                margin-top: 10px;
                font-size: 14px;
                font-weight: 400;
                color: #3f454b;
                line-height: 22px;
                // margin-top: 5px;
              }
            }
          }
        }
      }
    }
    .main_advantage_right {
      // margin-top: 118px;
      img {
        width: 567px;
        height: 435px;
      }
    }
  }
  .main_product {
    height: 660px;
    background: #fff;
    .main_product_box {
      width: 1200px;
      margin: 0 auto;
      .main_product_title1 {
        font-size: 36px;
        font-weight: 600;
        color: #1d2023;
        margin: 68px 0 19px;
      }
      .main_product_title2 {
        font-size: 14px;
        font-weight: 400;
        color: #1d2023;
        margin-bottom: 39px;
      }
      .main_product_ul {
        display: flex;
        justify-content: space-between;
        .main_product_li {
          width: 290px;
          height: 389px;
          background: #ffffff;
          // box-shadow: 0px 4px 16px 0px rgba(206, 206, 206, 0.5);
          border-radius: 2px;
          padding: 16px;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;
          align-items: center;
          .li_item_img {
            width: 258px;
            height: 184px;
          }
          .li_item_tit {
            font-size: 18px;
            font-weight: 600;
            color: #1d2023;
            margin: 16px 0 20px;
          }
          .li_item_tet {
            font-size: 14px;
            font-weight: 400;
            color: #3f454b;
            line-height: 22px;
            width: 218px;
            height: 60px;
            text-align: center;
          }
          button {
            margin-top: 16px;
            width: 96px;
            height: 28px;
            background: #ffffff;
            border-radius: 14px;
            border: 1px solid #dcdcdc;
            font-size: 12px;
            font-weight: 400;
            color: #78828c;
          }
        }
        .main_product_li:hover{
            box-shadow: 0px 4px 16px 0px rgba(206, 206, 206, 0.5);
        }
      }
    }
  }
  .main_service {
    height: 660px;
    display: flex;
    .service_left {
      width: 693px;
      height: 660px;
      background: linear-gradient(214deg, #2c69ff 0%, #2f68ff 100%);
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      .service_left_box {
        width: 282px;
        margin-right: 25px;
        img {
          width: 251px;
          height: 175px;
        }
        .main_service_title1 {
          font-size: 36px;
          font-weight: 600;
          color: #fff;
          margin: 56px 0 12px;
        }
        .main_service_title2 {
          width: 282px;
          font-size: 14px;
          font-weight: 400;
          color: #fff;
        }
      }
    }
    .service_right {
      width: 1226px;
      height: 660px;
      background: linear-gradient(214deg, #2c69ff 0%, #2f68ff 100%);
      padding: 100px 0 0 39px;
      box-sizing: border-box;
      .service_right_onenav {
        height: 85px;
        width: 720px;
        border-top: 1px solid #81a5ff;
        display: flex;
        // justify-content: space-between;
        li {
          // width: 130px;
          width: 50%;
          height: 85px;
          position: relative;
          .number {
            font-size: 33px;
            font-weight: 400;
            color: #ffffff;
            position: absolute;
            top: 16px;
            left: 16px;
            opacity: 0.3;
          }
          .tit {
            padding: 39px 0 0 0;
            text-align: center;
            font-size: 16px;
            font-weight: 400;
            color: #ffffff;
          }
        }
        .oneActive {
          background: #608eff;
          .tit {
            font-size: 22px;
            // text-align: right;
            padding-right: 10px;
          }
        }
      }
      .service_right_twonav {
        width: 720px;
        height: 34px;
        margin: 16px 0 26px;
        border: 2px solid #81a5ff;
        display: flex;
        align-items: center;
        margin-right: 30px;
        li {
          height: 34px;
          line-height: 34px;
          width: 25%;
          font-size: 14px;
          font-weight: 500;
          color: #ffffff;
          opacity: 0.5;
          text-align: center;
        }
        .twoActive {
          height: 38px;
          line-height: 38px;
          margin-left: -2px;
          opacity: 1;
          font-size: 16px;
          font-weight: 500;
          color: #2f74ff;
          background: #ffffff;
        }
      }
      .service_right_item {
        display: flex;
        width: 810px;
        flex-wrap: wrap;
        li {
          border: 2px solid #81a5ff;
          width: 250px;
          height: 140px;
          padding: 22px;
          box-sizing: border-box;
          margin: 0 20px 20px 0;
          .item_tit {
            font-size: 18px;
            font-weight: 500;
            color: #ffffff;
            margin-bottom: 10px;
          }
          .item_tet {
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            line-height: 22px;
          }
        }
      }
    }
  }
  .main_news {
    height: 660px;
    position: relative;
    .main_news_contnent {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 1820px;
      background: #fff;
      height: 803px;
      background: #ffffff;
      border-radius: 4px;
      .news_contnent {
        width: 1200px;
        margin: 0 auto;
        .news_title1 {
          font-size: 36px;
          font-weight: 600;
          color: #3f454b;
          margin: 56px 0 20px;
        }
        .news_title2 {
          font-size: 14px;
          font-weight: 400;
          color: #3f454b;
        }
        .news_top {
          display: flex;
          align-items: center;
          margin: 36px 0 32px;
          .left_img {
            width: 259px;
            height: 150px;
            background: #a7a7a7;
            z-index: 2;
          }
          .right_txt {
            margin-left: -42px;
            width: 983px;
            height: 209px;
            background: #ededf6;
            padding: 53px 32px 0 62px;
            box-sizing: border-box;
            display: flex;
            justify-content: space-between;
            .txt {
              width: 750px;
              .txt_tit {
                font-size: 18px;
                font-weight: 500;
                color: #1d2023;
                font-weight: 600;
                margin-bottom: 12px;
              }
              .txt_tit:hover{
                color: #3075FF;
              }
              .txt_tet {
                font-size: 14px;
                font-weight: 400;
                color: #3f454b;
                line-height: 22px;
              }
            }
            .time {
              text-align: right;
              .time_day {
                font-size: 32px;
                color: #3f454b;
              }
              .time_year {
                font-size: 20px;
                color: #b0b9c3;
              }
            }
          }
        }
        .news_bom {
          display: flex;
          flex-wrap: wrap;
          li {
            width: 570;
            display: flex;
            margin-bottom: 32px;
            .left_time {
              width: 108px;
              height: 99px;
              background: #ededf6;
              padding: 20px;
              box-sizing: border-box;
              text-align: right;
              .t_day {
                font-size: 24px;
                font-weight: 500;
                color: #3f454b;
              }
              .t_year {
                font-size: 16px;
                color: #b0b9c3;
                line-height: 22px;
              }
            }
            .right_newtxt {
              width: 461px;
              height: 146px;
              padding: 20px 22px;
              box-sizing: border-box;
              .n_tit {
                font-size: 18px;
                font-weight: 600;
                color: #1d2023;
              }
              .n_tit:hover{
                color: #3075FF;
              }
              .n_tet {
                line-height: 23px;
                margin-top: 11px;
                font-size: 14px;
                font-weight: 400;
                color: #3f454b;
              }
            }
          }
        }
      }
    }
  }
  .main_friend {
    // height: 1177px;
    background: linear-gradient(132deg, #258cef 0%, #3075ff 100%);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .friend_content {
      width: 1200px;
      margin: 0 auto;
      padding-top: 150px;
      .friend_title {
        margin: 90px 0 64px;
        font-size: 36px;
        font-weight: 600;
        color: #ffffff;
      }
      .friend_top {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        .friend_logo {
          img {
            width: 1040px;
            height: 419px;
            display: block;
            margin: 0 auto;
          }
        }
      }
      .friend_center {
        .friend_cardul {
          display: flex;
          flex-wrap: wrap;
          .friend_cardli {
            margin-right: 60px;
            // margin-bottom: 60px;
            .cardli_left {
              width: 487px;
              height: 211px;
            }
            .cardli_right {
              height: 80px;
              margin-left: 18px;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              .card_name {
                font-size: 18px;
                font-weight: 600;
                color: #1d2023;
                line-height: 25px;
              }
              .card_phone {
                span {
                  display: block;
                  font-size: 14px;
                  font-weight: 400;
                  color: #1d2023;
                }
              }
            }
          }
          .friend_cardli:first-child {
            margin-left: 80px;
          }
        }
      }
    }
    .friend_bom {
      height: 419px;
      background-image: url("../assets/home_friend_bom.png");
      background-size: 100% 100%;
      min-width: 1200px;
      margin-top: -20px;
    }
  }
}
.banner_img {
  width: 100%;
  height: 660px;
}
.banner_box_mid{
  width: 1200px;
  margin:auto;
  position: relative;
}
.banner_mid_img{
  position:absolute;
  left: -200px;
  top:80px;
  width: 794px;
  height: 582px;
}
.banner_box{
  background-image: url("../assets/banner1.png");
  background-size: 100% 660px;
  height: 660px;
}
.banner_box_cont{
  position: absolute;
  top: 120px;
  left: 400px;
  color: #fff;
  width: 560px;
  text-align: center;
}
.banner_cont_title{
  font-size: 36px;
  font-weight: 600;

}
.banner_cont_english{
  font-size: 24px; 
  font-weight: 300;
}
.banner_cont_deilt{
  font-size: 16px;
  font-weight: 300;
  color: #FCFDFE;
}
.banner_box_cont2{
  position:absolute;
  top: 350px;
  left: 617px;
}
.banner_box2_title{
  color: #fff;
  font-size: 16px;
  font-weight: 500;
}
.banner_box2_value{
  width: 608px;
  color: #DFE9FD;
  font-size: 12px;
  margin-top: 12px;
  line-height: 25px;
}
</style>