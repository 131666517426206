import Vue from 'vue'
import VueI18n from 'vue-i18n'
import locale from 'element-ui/lib/locale'
import messages from './lang'

// let JsSrc = (navigator.language || navigator.browserLanguage).toLowerCase();
// if (JsSrc.indexOf('zh') !== -1) {  //中文
//   sessionStorage.setItem('lang', 'zh')
// } else if (JsSrc.indexOf('ko') !== -1) {  //韩文
//   sessionStorage.setItem('lang', 'ko')
// } else if (JsSrc.indexOf('en') !== -1) {   //英文
//   sessionStorage.setItem('lang', 'en')
// }
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale:localStorage.getItem('lang') ?localStorage.getItem('lang') : 'zh',
  messages,
  silentTranslationWarn: true
})
locale.i18n((key, value) => i18n.t(key, value)) //重点：为了实现element插件的多语言切换




export default i18n