import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import axios from './api/https'
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import AppHeader from "./components/appHeader";
Vue.use(AppHeader)
import AppFooter from "./components/appFooter";
Vue.use(AppFooter)

import vuescroll from 'vuescroll/dist/vuescroll-native';
import 'vuescroll/dist/vuescroll.css';

// 国际化
import i18n from './i18n/i18n'

Vue.prototype.$http = axios

Vue.use(vuescroll);

//路由跳转后，页面回到顶部
router.afterEach(() => {
  console.log(document.body.scrollTop)
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
})

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
