import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
//
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  // saas产品
  {
    path: "/saas",
    name: "saas",
    component: () =>
      import("../views/saas.vue")
  },
  // 产品中心
  {
    path: "/product",
    name: "product",
    component: () =>
      import("../views/product.vue")
  },
  // 产品中心钱包
  {
    path: "/productwallet",
    name: "productwallet",
    component: () =>
      import("../views/productWallet.vue")
  },
  // 产品中心云链物易
  {
    path: "/producteasy",
    name: "producteasy",
    component: () =>
      import("../views/productEasy.vue")
  },
  // 产品中心浏览器
  {
    path: "/productbrowser",
    name: "productbrowser",
    component: () =>
      import("../views/productBrowser.vue")
  },
  // 解决方案resolveCase
  {
    path: "/resolvecase",
    name: "resolveCase",
    component: () =>
      import("../views/resolveCase.vue")
  },
  // 资讯 advisory
  {
    path: "/advisory",
    name: "advisory",
    component: () =>
      import("../views/advisory.vue")
  },
  //资讯详情 advisoryDetailts
  {
    path: "/advisorydetailts",
    name: "advisoryDetailts",
    component: () =>
      import("../views/advisoryDetailts.vue"),
    meta:{
      type:2
    }  
  },
  // 加入我们 addWe
  {
    path: "/addwe",
    name: "addWe",
    component: () =>
      import("../views/addWe.vue"),
  },
  {
    path: "/ss",
    name: "saas",
    component: () =>
      import("../components/HelloWorld.vue")
  },
  // 关于我们
  {
    path: "/about",
    name: "about",
    component: () =>
      import("../views/about.vue")
  },
  // 生态应用
  {
    path: "/apply",
    name: "apply",
    component: () =>
      import("../views/apply.vue")
  },
];

const router = new VueRouter({
  routes,
});

export default router;
