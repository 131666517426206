<template>
  <div id="app">
    <!-- <vue-scroll :ops="ops"  @handle-scroll="handleScroll"> -->
      <app-header :types="$route.meta.type" :class="Rscroll ? 'Rscroll' : ''"></app-header>
      <router-view />
      <app-footer></app-footer>

    <!-- </vue-scroll> -->
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      ops: {
        vuescroll: {
          mode: "native",
          sizeStrategy: "percent",
          detectResize: true,
        },
        scrollPanel: {
          initialScrollY: false,
          initialScrollX: false,
          scrollingX: false,
          scrollingY: true,
          speed: 300,
          easing: undefined,
          verticalNativeBarPos: "right",
        },
        rail: {
          background: "transparent",
          opacity: 0,
          size: "6px",
          specifyBorderRadius: false,
          gutterOfEnds: null,
          gutterOfSide: "2px",
          keepShow: false,
        },
        bar: {
          showDelay: 500,
          onlyShowBarOnScroll: true,
          keepShow: false,
          background: "transparent",
          opacity: 1,
          hoverStyle: false,
          specifyBorderRadius: false,
          minSize: false,
          size: "6px",
          disable: false,
        },
      },
      Rscroll: false,
    };
  },
  mounted() {
    document.addEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    handleScroll() {
      this.Rscroll = window.pageYOffset > 50;
    },
  },
  created() {},
};
</script>
<style lang="scss">
* {
  padding: 0;
  margin: 0;
}
ul li {
  list-style: none;
}
a {
  text-decoration: none;
}
input {
  outline: none;
  border: none;
  background: transparent;
}
button {
  border: none;
  background: transparent;
}
.vertical {
  width: 100%;
  word-break: break-all; //在恰当的断字点进行换行
  display: -webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp: 1; /**显示的行数**/
  overflow: hidden; /**隐藏超出的内容**/
}
.vertical2 {
  width: 100%;
  word-break: break-all; //在恰当的断字点进行换行
  display: -webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp: 2; /**显示的行数**/
  overflow: hidden; /**隐藏超出的内容**/
}
.vertical3 {
  width: 100%;
  word-break: break-all; //在恰当的断字点进行换行
  display: -webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp: 3; /**显示的行数**/
  overflow: hidden; /**隐藏超出的内容**/
}
.vertical7 {
  width: 100%;
  word-break: break-all; //在恰当的断字点进行换行
  display: -webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp: 7; /**显示的行数**/
  overflow: hidden; /**隐藏超出的内容**/
}
.vertical6 {
  width: 100%;
  word-break: break-all; //在恰当的断字点进行换行
  display: -webkit-box; /**对象作为伸缩盒子模型展示**/
  -webkit-box-orient: vertical; /**设置或检索伸缩盒子对象的子元素的排列方式**/
  -webkit-line-clamp: 6; /**显示的行数**/
  overflow: hidden; /**隐藏超出的内容**/
}
.Rscroll {
  background: #fff !important;
  box-shadow: 0px 2px 10px 0px rgba(3, 62, 182, 0.08) !important;
  z-index: 9999;
  transition: all 0.9s;
  .center_box_left {
    background-image: url("./assets/hesder_logo_b.png") !important;
  }
  .center_box_right {
    li,
    a {
      color: #1d2023 !important;
    }
    li:hover,
    a:hover {
      color: #3075ff !important;
    }
    .activeTab{
      color: #3075ff!important;
    }
    .activeTab  a{
      color: #3075ff!important;
    }
  }
  .el-dropdown-link {
    color: #1d2023 !important;
  }
}

 /*滚动条样式*/
  ::-webkit-scrollbar {
      width: 10px;    
      /*height: 4px;*/
  }
  ::-webkit-scrollbar-thumb {  //滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
      border-radius: 10px;
      -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      background: rgba(0,0,0,0.2);
  }
  ::-webkit-scrollbar-track { //滚动条的轨道（里面装有Thumb）
      // -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
      border-radius: 0;
      background: rgba(0,0,0,0);
  }
  ::-webkit-scrollbar-track-piece{  //内层轨道，滚动条中间部分（除去）
    background-color:rgba(0,0,0,0);
  }
  ::-webkit-scrollbar{  //滚动条整体部分
    opacity: 0;
  }
</style>
