import enLocale from 'element-ui/lib/locale/lang/en'
const en = {
  header:{
    home:"Home",
    sass: "SaaS Products",
    product:"Product Center",
    productWallet: "Cloud Chain Wallet",
    productCard: "YunLian Deposit Certificate",
    producteasy: "Yunlianwuyi",
    productbrowser: "Blockchain Browser",
    case: "Solution",
    caseHaer: "Government People's ",
    caseHaer2: "Reliable financial ",
    caseHaer3: "Medical health ",
    caseHaer4: "Integrated Application ",
    caseList: "Wisdom and Protection",
    caseList2: "Smart Farmers",
    caseList3: "Agricultural Traceability",
    caseList4: "Electronic Certificate",
    caseList5: "Bill circulation",
    caseList6: "Digital Logistics",
    caseList7: "Professional Certification",
    caseList8: "Proof of Equity",
    caseList9: "Insurance Management",
    caseList10: "Blockchain loan credit",
    caseList11: "Supply Chain Finance",
    caseList12: "Payment and settlement",
    caseList13: "Financial Audit",
    caseList14: "Prescription circulation",
    caseList15: "Food Safety Traceability",
    caseList16: "Blockchain Prevention",
    caseList17: "Health Service",
    caseList18: "counterfeiting",
    caseList19: "Treatment of donations",
    caseList20: "Blockchain settlement",
    caseList21: "Blockchain copyright",
    about:"Understand",
    addwe: "Join",
    apply: "ecological"
  },
  home:{
    bannerTitel: "A decentralized genetic health ecological chain",
    bannerTitelValle: "Blockchain-based data transactions and data services provide you with a clearer and more accurate all-round user portrait",
    bannerWe: "About Us",
    bannerWeValle: "Cloud Chain Technology originated in 2016. It is a high-tech enterprise with global blockchain technology application development as its main direction. The company is composed of senior entrepreneurs and top blockchain IT professionals, mainly engaged in relevant blocks The development and customization of the operating system of the chain technology, the design and production of the landing application of the blockchain scene. The research and development of the public chain (cloudchain), private chain, alliance chain and data chain have been independently completed. The company's main blockchain Technology development, technical support, and technical services are committed to applying blockchain technology to all walks of life.",
    caseTitle: "Global professional blockchain technology solution service provider",
    caseTitleValue: "Yunchain Technology is committed to the research and development of the underlying technology of the blockchain, the public chain of the independent knowledge industry, and the forward-looking research of the application of the blockchain",
    caseList: "It integrates the display center, data center, research and early warning center, and operation command center to form a cloud chain smart sanitation data center platform system for the 'smart brain' of urban sanitation management.",
    caseList2: "Agricultural product quality and safety traceability technology system and standard specifications based on big data and blockchain, and a cloud-chain smart agricultural trade platform that integrates edible agricultural product production, processing, storage, transportation, and inspection information.",
    caseList3: "Agricultural product traceability platform uses blockchain and Internet of Things technology to solve the problem of authenticity of traceability information. Through marketing capabilities, it drives rapid business growth.",
    caseList4: "An electronic certificate platform based on blockchain, identity authentication and a trusted data service platform. Realize the 'one-time generation and multiple reuse' of electronic certificates and provide multi-party trust infrastructure for government affairs in different places.",
    caseList5: "The blockchain bill circulation platform combines the non-tamperable and traceable features of the blockchain to provide bills on the chain and circulation services for other departments such as finance. Under the protection of privacy and security, it provides information such as medical bills Applications in multiple government affairs and people’s livelihood scenarios, such as reimbursement in different places, provide technical support.",
    caseList6: "Based on technologies such as blockchain, distributed digital identity, trusted computing, privacy protection, and financial-level risk control, it solves the problems of logistics transportation and proof of the authenticity of funds, and achieves data cross-checking. It is suitable for road freight, shipping, and more Multiple logistics and supply chain service scenarios such as intermodal transportation.",
    caseList7: "Integrate vocational qualification certification participants to join the blockchain alliance, build a complete vocational qualification certification ecology in the education and vocational fields, and provide a complete cooperation foundation for vocational certification.",
    caseList8: "Provide a one-stop online copyright protection solution for work content production institutions or content operation companies that integrates rights storage, communication monitoring, electronic evidence collection, and judicial rights protection litigation.",
    caseList9: "Information such as personal health status and accident records may be uploaded to the blockchain, so that insurance companies can obtain risk information more timely and accurately when customers apply for insurance, thereby reducing underwriting costs and improving efficiency.",
    caseList10: "After obtaining authorization, you can directly complete the credit investigation by directly accessing the corresponding information data of the blockchain, without having to apply for credit information inquiry.",
    caseList11: "Blockchain technology can effectively solve the problem of information asymmetry in the warehousing, logistics, and supervision of goods. Based on blockchain digital assets for financing, companies provide convenient and effective financing channels.",
    caseList12: "With the help of blockchain technology, the speed, cost, transparency and security of payment and settlement are greatly improved.",
    caseList13: "Blockchain technology can ensure the integrity, permanence and unchangeability of all data, and can effectively solve the difficulties and pain points of the audit industry in transaction forensics, tracking, correlation, and backtracking.",
    caseList14: "Ensure the safe and reliable circulation of prescriptions among hospitals, pharmacies, and patients, ensure that prescription information is not tampered with, and ensure the effectiveness of one-time prescription dispensing.",
    caseList15: "Information related to the entire process from production, processing, packaging to transportation, and food circulation is uploaded in real time to ensure food safety.",
    caseList16: "Everyone's whereabouts can be traced, control the source of infection as soon as possible, and minimize the damage of the epidemic.",
    caseList17: "Ensure the safety, credibility, sharing and circulation of personal health data, so as to realize remote signature confirmation of patients' family members, and cross-institutional circulation, tracking and auditing of health data.",
    caseList18: "Tracing detailed product information, such as origin, company/factory certification information, test reports, etc., to protect the rights and interests of businesses and consumers.",
    caseList19: "Guarantee the source of each donation and the authenticity of its circulation, so that every donation will be implemented to achieve the greatest value.",
    caseList20: "Blockchain settlement system, open and transparent transaction process, has the advantages of high efficiency, low cost and high credibility.",
    caseList21: "The blockchain copyright platform has the advantages of reducing costs and improving efficiency in the whole process, fast and convenient registration of rights, reliable and efficient forensics, high-precision whole network monitoring, and reliable and efficient forensics.",
    advantageTitle: "Our core competitive advantage",
    advantageIntroduce: "Yunchain Technology is committed to the research and development of the underlying technology of the blockchain, the public chain of the independent knowledge industry, and the forward-looking research of the application of the blockchain",
    advantageListTitle: "Support multi-chain docking",
    advantageListTitle2: "Wide application scenarios",
    advantageListTitle3: "Independent intellectual property rights",
    advantageListTitle4: "Research on the underlying technology of blockchain",
    advantageListValue: "Support multiple public chain docking application scenarios such as BTC, ETH, Hyperledger, etc.",
    advantageListValue2: "Scenario applications involving government affairs, finance, agriculture and other fields",
    advantageListValue3: "Owns two major invention patents and 10 major software copyrights",
    advantageListValue4: "Research and Application of Efficient Storage Technology, Consensus Mechanism",
    productTitle: "A well-built star product",
    productIntroduce: "Create personalized solutions for business characteristics of various industries",
    productName: "Blockchain domain name",
    productList: "SaaS platform is a standardized blockchain product, which can conveniently provide flexible customization, instant deployment, and rapid integration application platform",
    productList2: "The cloud chain wallet is a digital asset management tool that integrates digital currency management, storage, transfer, value-added, etc.",
    productList3: "The cloud chain deposit certificate is a self-developed product that integrates on-chain deposit certificates, digital deposit certificates, and verification and credit enhancement",
    productList4: "The blockchain domain name is a self-developed product that integrates a domain name, domain name browser, custom domain name and domain name mall",
    productBtn: "View Details",
    customizedTitle: "Customized Product Service",
    customizedTitleVallue: "Yunchain Technology is committed to the research and development of the underlying technology of the blockchain, the public chain of the independent knowledge industry, and the forward-looking research of the application of the blockchain",
    customizedHeader: "Customized Development",
    customizedHeader2: "underlying development",
    customizedList: "Efficient and low cost",
    customizedList2:"Data Tracking",
    customizedList3: "Multi-node system",
    customizedList4:"Smart Contract",
    customizedList5:"Universal Version",
    customizedList6:"Customized Version",
    customizedVallue: "In the blockchain network, the friction cost of completing value transfer and exchange while ensuring information security is almost zero",
    customizedVallue2: "The data in the blockchain can be traced back to the source, and the information is open and transparent for tracking and verification. The use of blockchain technology can construct a set of non-tamperable records for items or data.",
    customizedVallue3: "Each node in the blockchain participates in data recording and storage. When some nodes are damaged, the system can still operate normally, and the sustainability and safety are guaranteed.",
    customizedVallue4: "Each transaction in the blockchain is embedded with the concept of script, so that value interaction activities can be executed under specific conditions, and can specify the purpose and direction of specific transaction funds.",
    customizedVallue5: "Network deployment: one-click deployment, hybrid deployment, pluggable consensus, endorsement by public trust nodes",
    customizedVallue6: "Network management: node control, node addition, node cancellation, light node authorization",
    customizedVallue7: "Network management: visual monitoring, stable operation and maintenance, blockchain browser, development API, real-time monitoring, visual deployment, multi-language architecture",
    partnerTitle: "Professional and reliable partner",
    businessTitle: "Please contact us for business cooperation"
  },
  sass:{
    bannerTitle: "Cloud Chain SaaS Platform",
    bannervallue: "The cloud chain SaaS platform is a standardized blockchain product, which can conveniently provide flexible customization, instant deployment, and rapid integration of application platforms. It can provide web-based application customization, development, and deployment tools, and can realize SaaS applications without programming , Stability, and deployment capabilities. And it can ensure that products are quickly stabilized and deployed, and that applications can run at a higher level of performance.",
    bannerBtn: "Consult now",
    advantageTitle: "Platform Advantage",
    advantageHeader: "Low R&D cost",
    advantageHeader2: "Reduce time consumption",
    advantageHeader3: "With flexibility",
    advantageHeader4: "Security",
    advantageList: "To provide services on the basis of subscription, you can expand and reduce its expenditure according to your own needs; in addition, achieve more functions and benefits at a lower cost",
    advantageList2: "Just register to start using, you can better focus on its core business",
    advantageList3: "It can provide customers with greater flexibility. Charges are based on their usage, and will only incur more charges when they gain additional benefits from the product. Gradually use the product more and more over time, eliminating the opposite They are not sure whether the software that can meet their needs permanently will pay a large one-time worry about the software",
    advantageList4: "The information isolation mechanism used can ensure that the information space of each customer is independent of each other, and users cannot access any data of non-owner companies",
    traitTitle: "Platform Features",
    traitList: "Provide multiple versions, different price ranges and different fees",
    traitList2: "Provide free basic functions, then charge for value-added services, order on demand, and choose more freely",
    traitList3: "Flexible activation and suspension, can be used anytime, anywhere",
    traitList4: "Guide to quasi-face-to-face use",
    featuresTitle:"Platform Features",
    featuresHeader: "API deposit and withdrawal",
    featuresHeader2: "Wallet docking",
    featuresHeader3: "Payment interface",
    frameworkTitle: "Platform Architecture"
  },
  // 云链钱包
  productWallet:{
    bannerTitle: "Cloud Chain Wallet",
    bannervallue: "Cloud wallet is a digital asset management tool that integrates digital currency management, storage, transfer, value-added, etc., developed by Cloud Chain Technology after 2 years. It is safe, convenient and easy to use. In addition to solving the current digital currency management In addition, it also uses non-network technology to solve the current digital currency's non-network storage, non-network transfer, one-click conversion of hot and cold wallets, and a safe and fast mobile blockchain 'new wallet'.",
    featuresTitle:"Product Features",
    featuresHeader:"Asset Management",
    featuresHeader2: "No network transmission",
    featuresHeader3: "Globalization",
    featuresHeader4: "One-click conversion between hot and cold wallets",
    featuresList: "Support multi-chain storage and multi-chain switching, users can realize asset flow through currency transfer and collection, and obtain the products and services they need through global payment",
    featuresList2: "You can also transfer and collect accounts without network, without network restrictions anytime, anywhere",
    featuresList3:"Cloudwallet allows users all over the world to use it conveniently and quickly, and develop multi-language versions, including: Chinese, English, Japanese, French, traditional and other multi-language versions, so that users all over the world need not worry about the wallet version language when using the wallet problem",
    featuresList4: "Achieve hot and cold one-key swap function. CoudWallet will use technical functions to solve when you do not need to transfer assets temporarily, you can cut off the Cloudwallet network, that is, cold wallet; when you need to use assets, one-click link The network can be restored to a hot wallet to make the assets more secure",
    advantageTitle: "Product advantage",
    advantageHeader: "Asymmetric encryption",
    advantageHeader2: "Multi-scene payment",
    advantageHeader3: "No sense of interaction",
    advantageList: "The method of using public key and private key to encrypt. Data encryption and decryption rely on these two values ​​for operation. The public key is generated by the private key. After the ciphertext is encrypted by the public key, only the corresponding private key can be used. Decryption, so the encrypted ciphertext can be spread in the public network environment. Even if it is intercepted in the middle, it will not be decrypted without the private key",
    advantageList2: "With the emergence of new payment scenarios, Cloudwallet will match this new payment scenario, such as car payment, and use Cloudwallet to meet the needs of people for transactions anytime, anywhere",
    advantageList3: "No longer relying on specific carriers for interaction, but using weak credit verification technologies such as ID-Mapping to associate the strong and weak IDs of users to more efficiently complete user identification and digital asset transfer to meet the needs of people for transactions anytime, anywhere ",
  },
  // 云链存证
  product:{
    bannerTitle: "Cloud Chain Deposit Certificate",
    bannervallue: "The cloud chain deposit certificate is a self-developed product that integrates on-chain deposit certificates, digital deposit certificates, and verification and credit enhancement. It uses on-chain and off-chain collaborative work, and the method of separating files and hash values. Only the hash value of the file is stored on the upper, the original file is stored under the chain, the hash value of the file is calculated, and the hash value on the chain is compared to find out whether the file has been tampered with, so that the file is tamper-proof and traceable , Trusted purpose.",
    featuresTitle:"Product Features",
    featuresHeader: "Certificate on chain",
    featuresHeader2: "Digital Deposit Certificate",
    featuresHeader3: "Authentication and authenticity enhancement",
    featuresList: "Upload the hash value of the file to the NULS public chain for storage to ensure that the file is tamper-proof, traceable and trustworthy",
    featuresList2: "Digital certificate of deposit, the deposit information on the chain is at a glance, and you can check your deposit information at any time",
    featuresList3: "Support the confirmation and authenticity of the deposit information, and the credit can be increased",
    advantageTitle: "Product advantage",
    advantageHeader: "safe and reliable",
    advantageHeader2: "Data is clear and available",
    advantageHeader3: "Certificate is not afraid of losing",
    advantageList: "Manual review, desensitization on the chain, safe and trustworthy deposit data",
    advantageList2: "Data information on the chain is clear and can be queried, and you can check it anytime, anywhere",
    advantageList3: "The digital certificate is stored locally, without fear of information loss, you can know it with a scan",
  },
  // 云链物易
  producteasy:{
    bannerTitle: "Cloud Chain of Things Easy",
    bannervallue: "Yunchain Wuyi is a self-developed product that integrates shopping malls, assets and services. The hash value generated by the block chain is uniquely corresponding to the product, which enables the digitalization of enterprise products and achieves uniqueness: the product of each product The code is unique, the product code is unique in the logistics path, and the relationship between the product code and the consumer is unique. It can effectively solve the problems of anti-counterfeiting and traceability of goods, consumer information security and privacy leakage.",
    featuresTitle:"Product Features",
    featuresHeader:"Mall",
    featuresHeader2:"Assets",
    featuresHeader3:"Service",
    featuresList: "The block chain commodity transaction method realizes 'one item, one code' to improve the anti-counterfeiting of the product, and the data cannot be tampered with, and the real situation of the product is also allowed.",
    featuresList2: "commodity reception, product transfer, product withdrawal",
    featuresList3: "Improve the management of personal privacy in the process of asset transactions, such as conducting anonymous transactions to reduce the risk of privacy leakage",
    advantageTitle: "Product advantage",
    advantageHeader: "Separation of property rights",
    advantageHeader2: "Guarantee the authenticity of the product",
    advantageHeader3: "Reduce circulation costs",
    advantageList: "The only corresponding relationship between products and consumers, realizing the digitalization of products",
    advantageList2: "Achieve one item, one code, you can directly understand the real situation of the product",
    advantageList3: "Direct exchange of goods on the platform to reduce logistics and transportation costs",
  },
  // 区块链浏览器
  productbrowser:{
    bannerTitle: "Blockchain Explorer",
    bannervallue: "Blockchain domain name is a product developed exclusively by Yunchain Technology. It is a passport to the blockchain world in the future. It can bind complex transfer addresses, DAPPs and other blockchain world content. Users pass The blockchain browser can enter the corresponding domain name to query the specific content on the blockchain, just like the user can query the content on the Internet by entering the URL through the browser.",
    featuresTitle:"Product Features",
    featuresHeader: "jieyi domain name",
    featuresHeader2: "Domain Browser",
    featuresHeader3: "Custom Domain Name",
    featuresHeader4: "Domain Mall",
    featuresList: "In addition to the value of convenient transfers and fast Internet access, the Jieyi domain name is also convenient for enterprises and individuals to create IP. The uniqueness and scarcity of the domain name will also derive potential investment value",
    featuresList2: "By parsing and processing public chain data, a single domain name can establish a mapping relationship with all public chain addresses. Users can access the domain name browser through a domain name that is easy to read and recognize and has commercial value to manage all their own blockchains. Assets and information",
    featuresList3: "Support private domain name customization, whether it is company name, personal name, special number, as long as you want, domain names with special meanings can be customized, special domain names also have investment value",
    featuresList4: "The domain names purchased by individuals can be traded freely in the domain name mall, and the domain name order is clear at a glance; the domain name classification recommendation, which category you want to buy, and which category you want to buy; the additional purchase of domain names, the purchase is completed with one click",
    advantageTitle: "Product advantage",
    advantageHeader: "rich resources",
    advantageHeader2: "Easy to manage",
    advantageHeader3: "Significantly marked",
    advantageList: "Blockchain domain names are rich in resources, with a large space for development and utilization, and you can get your own satisfactory domain name",
    advantageList2: "Users can manage all their own blockchain assets and information by accessing the domain name browser through a domain name that is easy to read and recognize and has commercial value",
    advantageList3: "Customized domain names are conducive to personal and corporate IP creation, and have investment value",
  },
  // 解决方案
  case:{
    introduceTitle: "Program Introduction",
    introduceBtn: "Solve the pain points",
    introduceHeader: "With the help of blockchain, environmental monitoring, environmental law enforcement, water treatment, garbage treatment, waste gas treatment, garbage sorting and recycling, etc. can be open and transparent, so as to protect the healthy development of the city.",
    introduceHeader2: "Deeply integrate blockchain technology with agricultural trade supply and marketing, and help epidemic prevention and control through intelligent and credible quality inspection and traceability technology with independent intellectual property rights.",
    introduceHeader3: "Using the agricultural product traceability code as the carrier of information transmission, it realizes the whole process of monitoring the planting, processing, circulation, storage and retail of various agricultural products.",
    introduceHeader4: "Realize 24 types of commonly used electronic certificates such as resident ID cards on the chain. On the basis of protecting personal privacy to the greatest extent, the inconvenience of corporate citizens carrying paper certificates will be greatly reduced.",
    introduceHeader5: "Combined with the non-tamperable and traceable characteristics of the blockchain, it can provide services such as bill on-chain and circulation, which protects privacy and security, and provides technical guarantee for applications in multiple government affairs and people's livelihood scenarios.",
    introduceHeader6: "The core of distributed digital identity, business orders and verification results, blockchain certification, multi-party data verification in a trusted computing environment, data-based credit extension, credit transfer, insurance alliance, etc.",
    introduceHeader7:"The education professional qualification certification service realizes the certification of the company’s training behavior data chain, uploads the training courses, training duration, obtained certificates and other data to the chain for supervision and verification, and provides certification data calling and query services for alliance members. Achieve information disclosure and unify standards.",
    introduceHeader8: "Based on the characteristics of the blockchain, a one-stop online protection that integrates rights storage, communication monitoring, electronic evidence collection, and judicial rights protection litigation.",
    introduceHeader9: "Information such as personal health status and accident records may be uploaded to the blockchain, so that insurance companies can obtain risk information more timely and accurately when customers apply for insurance, thereby reducing underwriting costs and improving efficiency.",
    introduceHeader10: "After obtaining authorization, you can directly complete the credit investigation by directly accessing the corresponding information data of the blockchain, without having to apply for credit information inquiry.",
    introduceHeader11: "Blockchain technology can effectively solve the problem of information asymmetry in warehousing, logistics, and supervision of goods. Based on blockchain digital assets for financing, enterprises provide convenient and effective financing channels.",
    introduceHeader12: "With the help of blockchain technology, the speed, cost, transparency and security of payment and settlement are greatly improved.",
    introduceHeader13: "Blockchain technology can guarantee the integrity, permanence and unchangeability of all data, and can effectively solve the difficulties and pain points of the audit industry in transaction forensics, tracking, association, and backtracking.",
    introduceHeader14: "Ensure the safe and reliable circulation of prescriptions among hospitals, pharmacies, and patients, ensure that prescription information is not tampered with, and ensure the effectiveness of one-time prescription dispensing.",
    introduceHeader15: "Information related to the whole process from production, processing, packaging to transportation, and food circulation is uploaded in real time to ensure food safety.",
    introduceHeader16: "Everyone's whereabouts can be traced, control the source of infection as soon as possible, and minimize the damage of the epidemic.",
    introduceHeader17: "Ensure the safety, credibility, sharing and circulation of personal health data, so as to realize the remote signature confirmation of patients' families, and the cross-organization circulation, tracking and audit of health data.",
    introduceHeader18: "Trace detailed product information, such as origin, company/factory certification information, test reports, etc., to protect the rights of merchants and consumers.",
    introduceHeader19: "Guarantee the source of every donation and the authenticity of circulation, so that every donation will be implemented, and the greatest value will be realized.",
    introduceHeader20: "Blockchain settlement system, open and transparent transaction process, has the advantages of high efficiency, low cost, and high credibility.",
    introduceHeader21: "Provide work winding, testing, and deposit certificates for all original works to protect the copyright rights of the original works.",
    introduceSolve: "Misreporting and tampering with emissions data; isolated data islands of various departments, unable to communicate; low public participation",
    introduceSolve2: "Food safety cannot be traced; the data information is not clear; the transaction data is too many and complex to be traceable.",
    introduceSolve3: "Security issues cannot be traced back in time, and the process of planting, processing, circulation, storage, and retail cannot be monitored.",
    introduceSolve4: "Personal information has been leaked, data has been tampered with; information is difficult to comprehensively collect; the system is unstable and the entire service is unavailable.",
    introduceSolve5: "The density is low, personal information is easy to leak; the cost of transportation, storage, and verification is high; paper bills are difficult to supervise and the workload is heavy.",
    introduceSolve6: "Logistics transportation and capital authenticity; data cross-checking; logistics industry data collaboration to improve production relations; matching value services.",
    introduceSolve7: "Qualification fraud, user vocational training behavior, learning process and qualification certificate and other authority records",
    introduceSolve8: "Lack of standardized services in the industry, unconnected information in the transaction process, difficulty in fair distribution of income, ownership disputes, and difficulty in controlling communication security issues.",
    introduceSolve9: "Insurance industry information is not circulated, underwriting costs are high, and the probability of fraudulent insurance increases; manual operation is slow and complicated.",
    introduceSolve10: "Information security issues, credit investigation data cannot be marketed, resulting in less credit investigation data, poor quality, and low correlation between data and credit.",
    introduceSolve11: "It is difficult for the financial system in the supply chain to prove its innocence, the risk control cost for the core enterprises of the supply chain is high, and the financing of the small and medium enterprises in the supply chain is difficult and the cost is high, which further affects the entire supply chain.",
    introduceSolve12: "Time cost and labor cost are high, problems such as information tampering easily occur in the liquidation process, and transaction transparency is low.",
    introduceSolve13: "The large and complex audit system places high requirements on auditors, leading to increased audit risks and costs, lack of independence, susceptibility to external interference, and inability to conduct independent audits completely objectively.",
    introduceSolve14: "Patients need to repeat a series of procedures for follow-up visits each time, which leads to troublesome follow-up visits; hospitals, pharmacies and other drug information are difficult to match, and prescriptions are blocked; supervision of counterfeit prescriptions, counterfeit prescriptions, and expired prescriptions are difficult.",
    introduceSolve15: "There are many food processing and circulation links, and there are many hidden safety hazards; food circulation information is scattered and effective supervision is not obtained, which makes it difficult to trace the source of food.",
    introduceSolve16: "There is a large flow of people, it is impossible to find the whereabouts of the infected people in the first time, the epidemic information cannot be shared, and it is easy to miss the golden epidemic prevention period and cause unnecessary harm.",
    introduceSolve17: "The security of personal health data cannot be guaranteed, there is no trustworthy organization, and health data cannot be shared and transferred, resulting in a waste of resources.",
    introduceSolve18: "Lack of a trustworthy anti-counterfeiting traceability system, market sales of counterfeit, fraud, and shoddy goods are common phenomena, which not only hits consumers' shopping experience but also damages the brand image of merchants.",
    introduceSolve19: "Charities lack necessary supervision and auditing, information is not open, operation methods are not transparent, and fundraising cannot be traced back, resulting in a lack of credit for charities.",
    introduceSolve20: "Traditional reconciliation of different institutions asynchronously process and generate their own business record files for gradual comparison, timeliness and error-prone; affected by geographical, time, supervision and other factors, it is difficult to establish a trust system between different institutions.",
    introduceSolve21: "Traditional copyright certification methods have complicated processes and lengthy cycles, which raises the threshold of copyright protection for creators; digital works spread quickly, and they are separated from traditional copyright certification methods, further increasing the difficulty of creators’ rights protection.",
    advantageList: "Tracking the 'three wastes' and carbon emissions is conducive to institutional supervision.",
    advantageList2: "Data exchange between departments and efficient cooperation.",
    advantageList3: "Ordinary people and recycling agencies access, participate in emission reduction in daily activities.",
    advantageList4: "Food to the table, the whole process is traceable.",
    advantageList5: "Full disclosure of supply chain related information.",
    advantageList6: "Buy food online, unified delivery, convenient and fast.",
    advantageList7: "Traceability platform system, full management of planting information.",
    advantageList8: "Access, inspection, processing, information collection, full tracking.",
    advantageList9: "Check the authenticity, trace the source, transparent consumption, safe and secure.",
    advantageList10: "Credible licenses, tamper-proof and traceable.",
    advantageList11: "Open up all departments' data information and share data.",
    advantageList12: "Encrypt a single message to ensure privacy and safety.",
    advantageList13: "Strengthen confidentiality, only the credit granting party can view the bill information, effectively preventing information leakage.",
    advantageList14: "E-bill links of various institutions, direct review and inspection, high efficiency and easy supervision.",
    advantageList15: "Cross-comparison of data on the chain through a variety of methods, fast calculation in seconds in an encrypted environment, to ensure data security, authenticity and credibility.",
    advantageList16: "With the authorization of the data owner, relevant stakeholders can obtain data in real time, thereby helping upstream and downstream enterprises and individual participants in the logistics industry to obtain corresponding services.",
    advantageList17: "Based on the blockchain shared network, the efficiency of business cooperation has been greatly improved from the asymmetry of information in the logistics industry, the untrustworthy operation of logistics enterprises, to the digital business operation process.",
    advantageList18: "Record the user's vocational training process, accurately match courses, classes, hours, grades, practice and other information, and build real and effective training behavior data.",
    advantageList19: "Using blockchain technology to record the vocational training process and vocational qualification certificates of users at different stages of learning, and the whole process of supervision to prevent forgery and certificate fraud.",
    advantageList20: "Build a user's skill profile in multiple dimensions, and broadly expand the use of skills in multiple fields, including employment, human resources and social subsidies, and corporate talent screening and promotion.",
    advantageList21: "Integrate dispersed business links into an online one-stop service, integrating rights and interests deposit certificates, communication monitoring, electronic evidence collection, and rights protection services.",
    advantageList22: "Effectively improve the efficiency of dispute resolution due to ownership issues.",
    advantageList23: "Create a trustable equity proof alliance chain, share related information on the chain, realize integration and data exchange in the smart contract and business application layer, and achieve fair and real-time income distribution through smart contracts.",
    advantageList24: "Solving the problem of information islands and effectively suppressing 'bad risks' fraudulent behaviors can also help simplify the daily work of insurance companies such as underwriting and claim verification.",
    advantageList25: "Guarantee the security of credit data, thereby establishing its own data sovereignty.",
    advantageList26: "Take users and use them for users to promote open sharing of data and social interconnection.",
    advantageList27: "Using blockchain technology to run digital bills makes the credit system of the entire business transmittable and traceable, which can effectively reduce the cost of capital risk control and the difficulty of financing for SMEs in the supply chain.",
    advantageList28: "Strengthen the tracking of the liquidation process to greatly improve the security of funds, which is conducive to departmental supervision and timely detection of hidden dangers in the liquidation process.",
    advantageList29: "The auditing methods are more comprehensive, the information is accurate and thorough, and it is convenient for internal and external search, survey and analysis, which reduces the cost of audit analysis and audit while improving audit efficiency.",
    advantageList30: "Using blockchain technology to form a multi-node alliance network such as hospitals, pharmacies, etc., electronic diagnosis and treatment records and electronic medical records are shared by multiple parties, breaking the island of medical data.",
    advantageList31: "Development-style nodes, each node can input data, the nodes are interconnected, each product independently traces the source code, the node scans the code, and leaves traces in chronological order to ensure plasticity.",
    advantageList32: "Personnel traces can be traced, and the source of infection can be found in time; ensure that different independent agencies share information about the epidemic at the same time, and accelerate the response of the health system in emergency situations.",
    advantageList33: "Using the full traceability, anti-tampering, privacy protection and other features of the blockchain to ensure the security of personal health data, it also facilitates real-time monitoring and early warning of public health events.",
    advantageList34: "With the help of blockchain technology, the information of the commodity raw material circulation process, production process, and marketing process is integrated and written into the blockchain to realize the traceability of the whole process of one thing and one code.",
    advantageList35: "Using blockchain technology to effectively monitor the source and flow of donations, so that the donations can be implemented.",
    advantageList36: "Break data and information islands, publicly donate data and distribute data, make data available in real time, and establish a good trust system.",
    advantageList37: "The blockchain settlement system establishes distributed node credit relationships through digital encryption and distributed consensus, thereby solving the problems of difficulty and high cost in establishing trust between payment nodes, and at the same time has the advantages of high efficiency and low cost.",
    advantageList38: "Traditional copyright authentication methods have complicated processes and lengthy cycles, which raises the threshold of copyright protection for original creators.",
    advantageList39: "Digital works spread quickly and break away from traditional copyright authentication methods, further increasing the difficulty of creators' rights protection.",
  },
  // 关于我们
  about:{
    bannerTitel: "About Us",
    bannerVallue: "Global professional blockchain technology solution service provider",
    aboutTitle: "About Cloud Chain",
    aboutVallue: "Yunchain Technology originated in 2016. It is a high-tech enterprise with global blockchain technology application development as its main direction. The company is composed of senior entrepreneurs and top blockchain IT professionals, mainly engaged in relevant blocks The development and customization of the operating system of the chain technology, the design and production of the landing application of the blockchain scene. The research and development of the public chain (cloudchain), private chain, alliance chain and data chain have been independently completed. The company's main blockchain Technology development, technical support, and technical services are committed to applying blockchain technology to all walks of life.",
    advantageTitle: "Core advantage",
    advantageList: "extensive experience",
    advantageList2: "Top Team",
    advantageList3: "Industry Cases",
    advantageList4: "Independent technology",
    advantageCont: "Professional full-process service, the products are verified by the customer market, stable and safe, to ensure the successful implementation of the project; adhere to the real economy and sustainable business model.",
    advantageCont2: "With senior entrepreneurs, top IT professionals in the blockchain, and a complete project implementation team, the entire project link has dedicated personnel, advance planning, and a mature product system.",
    advantageCont3: "Achieve application landing in many industries such as agricultural trade, environmental protection, government affairs, traceability, data, asset digitization, etc.,",
    advantageCont4: "Independent research and development has independently completed the development of public chain (cloudchain), private chain, alliance chain and data chain, etc., and has many patents and software copyrights.",
    attestationList: "Digital currency exchange matchmaking software",
    attestationList2: "Domain Browser",
    attestationList3: "Ether Planet Software",
    attestationList4: "Jieyi Domain Name Software",
    attestationList5: "Blockchain Service Network Qualified Developer",
    attestationList6: "Quality Management System Certification",
    attestationList7: "Wuhan Fangxian Chamber of Commerce Vice President",
    attestationList8: "Hubei Youth Association<br>Vice President Unit",
    attestationList9: "Hubei Youth Association<br>Strategic Partner",
    attestationList10: "Hubei Youth Association<br>Blockchain Base",
    attestationList11: "Wuhan Blockchain Alliance<br>Deputy Chairman Unit",
    attestationList12: "National Mobile Innovation <br>Blockchain Application ",
    attestationList13: "Optics Valley Alliance<br>President Unit",
    attestationList14: "2018 BLOCK HOT<br>Global Excellent Award",
    attestationList15: "2019 Blockchain Industry<br>Best   Award",
    attestationList16: "2018 Hurun Ranking<br>Innovative  TOP50",
    attestationList17: "2017 Fuzzy Summit<br>Excellent  Enterprise",
    attestationList18: "2018 Cloud Wallet <br>Most  Project Award",
    attestationTitle: "Development Route",
    qualification:"Certification"
  },
  // 加入云链
  addwe:{
    bannerTitle: "Join Us",
    bannervVallue: "If you also have dreams and unlimited talents, there is an infinite stage for you to sway",
    welfareTitle: "Employee Welfare",
    welfareList: "Sufficient basic protection",
    welfareList2: "festival welfare",
    welfareList3: "Paid annual leave",
    welfareList4: "Employee Care",
    welfareList5: "Full attendance reward",
    welfareList6: "Senior Service Award",
    welfareVallue: "Purchase endowment insurance, medical insurance, work injury insurance, unemployment insurance and maternity insurance to provide employees with basic social security protection",
    welfareVallue2: "A wealth of employee benefits, including: transportation subsidies, holiday benefits, team building activities, performance bonuses, etc.",
    welfareVallue3: "You can enjoy paid annual leave ranging from 5-15 days per year",
    welfareVallue4: "Track each employee's work and life conditions in real time after joining, and give them assistance within their ability",
    welfareVallue5: "Enjoy an additional 200 yuan full attendance bonus every month",
    welfareVallue6: "Old employees can enjoy a seniority bonus ranging from 100-500 yuan",
    jobTitle: "Hot Job",
    jobName: "GO Language Development Engineer",
    jobName2: "Java Development Engineer",
    jobName3: "Android Development Engineer",
    jobName4: "iOS Development Engineer",
    jobAdress: "Wuhan",
    jobYear: "3-5 years of work experience",
    jobIntroduce: "I am engaged in the research and development of major public chains of blockchain;",
    jobIntroduce2: "Promote the connection between blockchain technology and the company's business, organize requirements and write plans;",
    jobIntroduce3: "Complete relevant development documents;",
    jobIntroduce4: "Responsible for project development, maintenance, business requirements sorting and docking;",
    jobIntroduce5: "According to needs, dismantle tasks and evaluate time to form a development plan, ensure the execution of the plan, and update the plan daily;",
    jobIntroduce6: "Pay attention to the quality of development and self-test, quickly locate and solve problems, and ensure the stable operation of products;",
    jobIntroduce7: "Develop application programs based on the Android platform based on product prototypes and UI design drawings, write reusable user interface components, and realize the functions of each module;",
    jobIntroduce8: "Continuously optimize the front-end experience and page response speed, and ensure compatibility with various models and execution efficiency;",
    jobIntroduce9: "Responsible for the company's native iOS development;",
    jobIntroduce10: "Participate in program discussion and technical research, responsible for program framework design and core function development;",
    jobIntroduce11: "Independently complete the problems found in iOS software development and testing to ensure the quality of development;",
    jobBtn: "Join Now",
    deliverTitle: "Delivery Method",
    deliverName: "Contact HR",
    deliverTitle: "Add HR WeChat, dedicated service post consultation",
    deliverEamil: "Send your resume to the recruitment email:",
    deliverValue: "The HR team will process the resume within 5 working days after receiving it. If the resume is approved, you will be notified immediately."
  },
  // 生态
  apply:{
    bannerTitle: "Ecological Application",
    bannerValue: "With the continuous efforts of many partners and projects, the cloud chain ecosystem is developing rapidly.",
    applyBtn: "Download",
    applyGoto: "Go",
    applyHeader: "Cloud Chain Wallet",
    applyHeader2: "Blockchain Domain Name",
    applyHeader3: "Cloud Chain Certificate",
    applyHeader4: "Yunlian Wuyi",
    applyHeader5: "Nongdinghui Cloud Chain Smart Farming Platform",
    applyHeader6: "Cloud Chain Domain Name",
    applyValue: "Cloud Chain Wallet is a digital asset management tool that integrates digital currency management, storage, transfer, value-added, etc., developed by Cloud Chain Technology after a year of research and development. It is safe, convenient, and simple to use. In addition to solving the current digital currency management, it will continue to iteratively enrich its ecological applications.",
    applyValue2: "The product developed exclusively by Yunchain Technology. It is a passport to the blockchain world in the future. It can bind complex transfer addresses, DAPPs and other contents of the blockchain world. The user enters the corresponding content through the blockchain browser Domain name can be used to query specific content on the blockchain network, just as a user can query specific content on the Internet by entering a URL through a browser.",
    applyValue3: "Using on-chain and off-chain collaborative work, the file is separated from the hash value, only the hash value of the file is stored on the chain, and the original file is stored under the chain, and the hash value of the file is calculated. By comparing the hash values, you can find out whether the file has been tampered with, so as to achieve the purpose of tamper-proof, traceable and trustworthy.",
    applyValue4: "The hash value randomly generated by the blockchain is uniquely corresponding to the product, so that the enterprise product is digitized and unique: the product code of each product is unique, the product code logistics path is unique, and the relationship between the product code and the consumer is unique. It can effectively solve the problems of anti-counterfeiting traceability of goods, consumer information security and privacy leakage.",
    applyValue5: "The Cloud Chain Blockchain Agricultural Trade Demonstration Project uses a blockchain-based traceability system for edible agricultural products to establish a demonstration of agricultural products with a full traceability chain of wholesale, distribution and retail.",
    applyValue6: "Cloud Chain Technology, jointly developed and released by BSN-DDC, can be adapted to all public, alliance, and private chains, ensuring that domain owners can control the same domain name on all chains to point to any chain account, wallet, contract address, and DID file.",
    applyTitle: "Eco-Partner"
  },
  // 底部
  footers:{
    footerHeader: "Navigation",
     footerHeader2: "Corporate Honor",
     footerHeader3: "Hot Products",
     footerHeader4: "Contact Us",
     footerList: "SaaS Products",
     footerList2: "Product Center",
     footerList3: "Solution",
     footerList4: "Understand Cloud Chain",
     footerList5: "High-tech Enterprise",
     footerList6: "Technology'Little Giant' Enterprise",
     footerList7: "Quality Management System Certification",
     footerList8: "BSN Qualified Developer Certificate",
     footerList9: "Blockchain Domain Name",
     footerList10: "Cloud Chain Deposit Certificate",
     footerList11: "Yunlian Wuyi",
     footerAdress: "Address: Room 501, 5th Floor, Dingtai Guannan Building, No. 37, Gaoxin 2nd Road, Wuhan",
     footerPic: "Yunchain Technology Public Account",
     footerPic2: "Cloud Chain Store Certificate Mini Program"
  },
}
export default en
