import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    ids:sessionStorage.getItem("caseid")?sessionStorage.getItem("caseid"):0
  },
  mutations: {
    setCase(state,ids){
      state.ids=ids
    }
  },
  actions: {},
  modules: {}
});
